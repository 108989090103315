export enum KEY_CODES {
  /** act as Delete in Mac */
  BACKSPACE = 'Backspace',
  /** just for Mac machine to act as `delete` key */
  BACKSLASH = 'Backslash',
  SLASH = 'Slash',
  CONTROL = 'Control',
  /** used in Windows machine */
  DELETE = 'Delete',
  ENTER = 'Enter',
  ESCAPE = 'Escape',
  META = 'Meta',
}

export enum MODAL_TYPES {
  NEW_PROJ = 'NEW_PROJECT',
  LOCK_FILE = 'LOCK_FILE',
  NEW_DUCT = 'NEW_DUCT',
  NEW_PIPE = 'NEW_PIPE',
  NEW_EQMT = 'NEW_EQUIPMENT',
  DUCT_LENGTH = 'DUCT_LENGTH',
  PIPE_LENGTH = 'PIPE_LENGTH',
  CRCT_DUCT = 'CORRECT_DUCT',
  CRCT_PIPE = 'CORRECT_PIPE',
  CRCT_DLENGTH = 'CORRECT_DUCT_LENGTH',
  CRCT_PLENGTH = 'CORRECT_PIPE_LENGTH',
  CRCT_EQMT = 'CORRECT_EQUIPMENT',
  SUMMARY = 'SUMMARY_VIEW',
  /** project or page deletion confirmation modal! */
  DEL_PAGE = 'DELETE_PAGE_OR_PROJECT_CONFIRM',
  /** delete multiple pages */
  DEL_PAGES = 'DELETE_SELECTED_PAGES',
  /** Equipment setting for project */
  EQUIPMENT = 'EQUIPMENT_SETTING',
  LEAVE_PAGE = 'LEAVE_CHANGES_CONFIRM',
  CONTACT_US = 'CONTACT_US',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  ADD_GROUP = 'ADD_GROUP',
  ADD_CONTRIBUTORS = 'ADD_CONTRIBUTORS',
  BRUSH_SETTING = 'BRUSH_SETTING',
  /** popup Custom Fitting */
  CUSTOM_FITTING = 'CUSTOM_FITTING',
  /** Custom Length */
}
