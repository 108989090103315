/**
 * filter data collection to get part of that
 * @date 2024/10/18
 */

import * as _ from 'lodash-es';

import {
  BPSymbol,
  SYMBOL_MODE,
  SymbolFindResult,
  FITTING_TYPE,
  LENGTH_TYPE,
  SPECIAL_JOB_ITEM as SPI,
  iconFinder,
} from '../types';

export const symbolToFR = (smbl: BPSymbol): SymbolFindResult => {
  const icon = iconFinder(_.snakeCase(smbl.class));
  const hashStr = smbl.index.toString();
  const system = smbl.detail['system'] || '';
  return { ...smbl, icon, hash: hashStr, system };
};

const symbolToListItems = (results: BPSymbol[]): SymbolFindResult[] => {
  return results.map(symbolToFR);
};

/**
 * filter all symbols with specific type
 * FIXME: but for equipment it represent equipment `name` - 2023/05/26
 *
 * @param type_or_name item type or equipment name
 * @returns
 */
const filteringSymbolsBy = (
  type_or_name: string,
  symbols: BPSymbol[],
): SymbolFindResult[] => {
  const results = symbols.filter((symbol) => {
    const isTypeMatch = symbol.class === type_or_name;
    const isNameMatch = symbol.detail?.['name'] === type_or_name;
    return isTypeMatch || isNameMatch;
  });
  if (type_or_name === 'ALL') return symbolToListItems(symbols);
  return symbolToListItems(results);
};

/**
 * filter symbols just by item type
 *
 * @param type item type
 * @param symbols
 * @returns
 */
export const pickingSymbolJustBy = (type: string, symbols: BPSymbol[]) => {
  const visibleFilter = (smb: BPSymbol) => smb.mode !== SYMBOL_MODE.DEL;
  const visibleSymbols = symbols.filter(visibleFilter);
  return filteringSymbolsBy(type, visibleSymbols);
};

/**
 * While total correction group selected and subtype is `All`, need all the items of the group
 *
 * @param group
 * @param symbols
 * @returns
 */
export const pickingSymbolsByGroup = (group: string, symbols: BPSymbol[]) => {
  const visibleFilter = (smb: BPSymbol) => smb.mode !== SYMBOL_MODE.DEL;
  const visibleSymbols = symbols.filter(visibleFilter);
  // FIXME: do not need to consider symbol type, regardless `rect` or `circle`
  // @2024/04/10
  const fitingDuctFilter = (smb: BPSymbol) => smb.category === FITTING_TYPE.D;
  const fittingPipeFilter = (smb: BPSymbol) => smb.category === FITTING_TYPE.P;
  const lengthDuctFilter = (smb: BPSymbol) => {
    if (smb.class === SPI.RECT_DUCT) return true;
    return smb.category === LENGTH_TYPE.D;
  };
  const lengthPipeFilter = (smb: BPSymbol) => smb.category === LENGTH_TYPE.P;
  const equipmentsFilter = (smb: BPSymbol) => smb.category === FITTING_TYPE.E;

  // setup dynamic filter by group
  const groupFilter: { [key: string]: (smb: BPSymbol) => boolean } = {
    [FITTING_TYPE.D]: fitingDuctFilter,
    [FITTING_TYPE.P]: fittingPipeFilter,
    [LENGTH_TYPE.D]: lengthDuctFilter,
    [LENGTH_TYPE.P]: lengthPipeFilter,
    [FITTING_TYPE.E]: equipmentsFilter,
  };
  const nothing = () => false;
  const safeFilter = groupFilter[group] || nothing;
  const allSymbolsInGroup = visibleSymbols.filter(safeFilter);
  return symbolToListItems(allSymbolsInGroup);
};
