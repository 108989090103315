import * as _ from 'lodash-es';
import { useState, useEffect, ChangeEvent, useCallback } from 'react';

import { kebabCase } from '@/utils';

import { InputRendererProps } from '../inputs';
import { getSymbolDetailBy } from '../state';
import {
  FittingEntity,
  TKEvent,
  DUCTSHAPES as SP,
  // ductFieldsFinder,
  findFieldsByType,
  shapeFinder,
  iconFinder,
  FITTING_TYPE as FT,
  GenericObject,
  OMIT_FROM_ROUND,
  OMIT_FROM_SQUARE,
  ductFieldsOmmitedBy,
  LENGTH_FIELD,
  LENGTH_STR_FIELD,
} from '../types';
import {
  getDimensionBy,
  setDimensionBy,
  getSystemBy,
  lastShapeFor,
} from '../types/dimension-setting';

export const useDuctModal = (
  selectType: string, // ELBOW ...
  lazyCloseHandler: () => void,
  itemId?: string, // feature id, only available in symbol correction!
) => {
  const lastSystem = getSystemBy(selectType) || '';
  const [currentSystem, setCurrentSystem] = useState<string>(lastSystem); // system belonged
  const [currentType, setCurrentType] = useState(selectType); // symbol type in capitalized
  const [ductFields, setDuctFields] = useState(findFieldsByType(selectType)); // raw fields from config
  const [useShape, setUseShape] = useState(shapeFinder(selectType));
  // shape is complex to decide, so move to an effect to check!
  const [shapeType, setShapeType] = useState('');
  const [fieldProps, setFieldProps] = useState<{ [key: string]: string }>({}); // kebab case field pairs

  /** FIXME: ensure the value is in lower case
   * @2023/06/22
   */
  const safeShapeType = shapeType.toLocaleLowerCase();

  const ductFieldsFilterBy = useCallback(
    (shape: string) => {
      if (shape === SP.ROUND) {
        return _.difference(ductFields, OMIT_FROM_ROUND);
        // return ['diameter_1'];
      }
      if (shape === SP.SQUARE) {
        return _.difference(ductFields, OMIT_FROM_SQUARE);
      }
      return ductFields; // shapeType is not in use
    },
    [ductFields],
  );

  /**
   * figure out `fieldProps`
   */
  useEffect(() => {
    if (!currentType) return;
    // use cached dimension values or symobl detail
    // use detection values in correction workflow
    const values = itemId
      ? getSymbolDetailBy(itemId)
      : getDimensionBy(currentType);
    const fieldsWithShape = ductFieldsFilterBy(shapeType);
    const fieldPairs = _.pick(values, fieldsWithShape) as GenericObject;
    setFieldProps(fieldPairs); // construct key/value group
    // console.log(`>>> cached values for symbol:`);
    // console.log(values);

    // *** reset system property: ***
    const { system } = values;
    if (!system) {
      // console.warn(`## no system value!`);
      return;
    }

    // FIXME: check if system has a `/` to ensure it has,
    // in order to display its value with the right option
    // @2023/06/23
    const strSystem = system as string;
    const hasNoSlash = !strSystem.includes('/');
    const withSlash = [...strSystem].join('/');
    // add '/' manually!
    if (hasNoSlash) return setCurrentSystem(withSlash);
    return setCurrentSystem(strSystem);
  }, [currentType, itemId, ductFieldsFilterBy, shapeType]);

  /**
   * figure out `shapeType`
   */
  useEffect(() => {
    if (!useShape) return; // no need to consider shape

    // === Case 1: NEW fitting symbol creation ===
    if (!itemId) {
      const lastShape = lastShapeFor(selectType) || 'round';
      return setShapeType(lastShape);
    }

    // case 2: existing fitting symbol correction.
    const detail = getSymbolDetailBy(itemId);
    // console.log(detail);
    const { shape } = detail;
    shape && setShapeType(shape);
  }, [useShape, selectType, itemId]);

  /**
   * TODO: anyway to separate this function out of this hook? - 2023/07/13
   *
   * figure out the properties needed for each field input component
   *
   * @param fieldName each field name
   * @returns normal object
   */
  const inputFieldRenderer = (fieldName: string): InputRendererProps => {
    const kebabField = kebabCase(fieldName);
    const fieldValueChangeHandler = (key: string, value: string) => {
      // console.log({ key });
      const changedProps = { ...fieldProps, [key]: value };
      setFieldProps(changedProps);
    };
    const isLengthField = fieldName === LENGTH_FIELD;
    const isLengthInString = fieldName === LENGTH_STR_FIELD;
    const inputProps: InputRendererProps = {
      ...fieldProps,
      disabled: isLengthInString,
      fieldName, // `DefaultInput` need this
      fieldLabel: isLengthField ? 'LENGTH(in pixels)' : '',
      fieldValue: fieldProps[kebabField], // `DefaultInput` need this
      fieldHandler: fieldValueChangeHandler,
      blurHandler: () => null, // FIXME: placeholder for now - 2023/11/08
    };

    return inputProps;
  };

  const shapeChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setShapeType(event.target.value);
  };

  /**
   * figure out the attributes displayed on the right side panel
   * @returns `{}`
   */
  const createFeatureEntity = (id?: string): FittingEntity => {
    const icon = iconFinder(currentType);
    // consider shape field
    const fieldsWithShape = useShape
      ? { shape: shapeType, ...fieldProps }
      : fieldProps;
    // ignore unused attributes
    const fieldsOmmited = ductFieldsOmmitedBy(shapeType);
    const pickedProperties: {
      [key: string]: string;
    } = _.omit(fieldsWithShape, fieldsOmmited);

    const detail: FittingEntity = {
      category: FT.D,
      system: currentSystem,
      entityType: currentType || 'UNDEFINED',
      icon,
      toolType: FT.D,
      ...pickedProperties,
    };

    if (id) {
      detail.id = id;
    }

    return detail;
  };

  /**
   * doing 2 jobs:
   *
   * 1. notify map to draw new element with attributes object
   * 2. cache input values to reuse
   */
  const applyNewElementHandler = () => {
    const detail = createFeatureEntity();
    // notify map to start drawing ...
    const event = new CustomEvent(TKEvent.NEWELEMENT, { detail });
    document.dispatchEvent(event);

    // cache shape field
    const fieldsWithShape = useShape
      ? { shape: shapeType, ...fieldProps }
      : fieldProps;
    // cache input
    currentType && setDimensionBy(currentType, fieldsWithShape);

    // close
    lazyCloseHandler();
  };

  /**
   * Correct item(feature) from drawing
   *
   * @deprecated correction functionality moved to the total review panel
   * @returns void
   */
  const applyItemChangeHandler = () => {
    if (!itemId) return lazyCloseHandler();

    const detail = createFeatureEntity(itemId);
    // notify map to start drawing ...
    const event = new CustomEvent(TKEvent.CORRECTELEMENT, { detail });
    document.dispatchEvent(event);

    // cache shape field
    const fieldsWithShape = useShape
      ? { shape: shapeType, ...fieldProps }
      : fieldProps;
    // cache input
    currentType && setDimensionBy(currentType, fieldsWithShape);

    lazyCloseHandler();
  };

  const ductTypeChangeHandler = (event: ChangeEvent<HTMLSelectElement>) => {
    setCurrentType(event.target.value);
    setDuctFields(findFieldsByType(event.target.value));
    setUseShape(shapeFinder(event.target.value));
    setShapeType(lastShapeFor(event.target.value));
  };

  // lazy close, and dispatch cancel tool event...
  const cancelNewElementHandler = () => {
    lazyCloseHandler();
    const evt = new Event(TKEvent.CANCEL_DRAWING_TOOL);
    document.dispatchEvent(evt);
  };

  return {
    currentSystem,
    currentType,
    shapeType: safeShapeType,
    useShape,
    ductFields: ductFieldsFilterBy(safeShapeType),
    fieldProps, // exposed at 2024/02/28
    setCurrentSystem,
    ductTypeChangeHandler,
    inputFieldRenderer,
    applyNewElementHandler,
    applyItemChangeHandler,
    shapeChangeHandler,
    cancelNewElementHandler,
  };
};
