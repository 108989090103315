/**
 * List all the system names and color config
 * @2023/02/08
 */

import { removeSlashes } from '@/utils';

import { FITTING_TYPE, FLUID_TYPE } from '.';

export interface Option {
  readonly label: string;
  readonly value: string;
}

export const createCreatableSelectOption = (label: string, value?: string) => ({
  label,
  value: value || label.toLowerCase().replace(/\W/g, ''),
});

export const defaultSystems = [
  createCreatableSelectOption('One'),
  createCreatableSelectOption('Two'),
  createCreatableSelectOption('Three'),
];

export const createTKSystem = (
  label: string,
  category: FITTING_TYPE,
): TKSystem => ({
  short: label.toUpperCase(),
  fullName: label,
  color: '',
  category,
});

export const createMoreSystem = (category: FITTING_TYPE) => ({
  short: '*',
  color: '',
  category,
  fullName: 'Show more...',
});

/**
 * system definition of drawing
 */
export type TKSystem = {
  short: string;
  fullName: string;
  color: string;
  category: FITTING_TYPE;
  fluidType?: FLUID_TYPE;
};

/** HVAC Duct systems  */
export const DUCT_SYSTEMS = [
  {
    short: 'R/A',
    fullName: 'Return Air',
    color: '#426ab3',
    category: FITTING_TYPE.D,
  },
  {
    short: 'S/A',
    fullName: 'Suppy Air',
    color: '#d71345',
    category: FITTING_TYPE.D,
  },
  {
    short: 'E/A',
    fullName: 'Exhaust Air',
    color: '#b2d235',
    category: FITTING_TYPE.D,
  },
  {
    short: 'G/A',
    fullName: 'General Air ',
    color: '#f47920',
    category: FITTING_TYPE.D,
  },
  {
    short: 'V/A',
    fullName: 'Vent Air',
    color: '#00ae9d',
    category: FITTING_TYPE.D,
  },
  {
    short: 'W/E',
    fullName: 'Washroom Exhuast ',
    color: '#ffe600',
    category: FITTING_TYPE.D,
  },
  {
    short: 'T/R',
    fullName: '?',
    color: '#274d3d',
    category: FITTING_TYPE.D,
  },
  {
    short: 'GEA',
    fullName: 'General Exhaust Air ',
    color: '#cd9a5b',
    category: FITTING_TYPE.D,
  },
  {
    short: 'O/A',
    fullName: 'Outside Air',
    color: '#817936',
    category: FITTING_TYPE.D,
  },
];

/** Pipe Systems */
export const PIPE_SYSTEMS: TKSystem[] = [
  /** FLUID - WATER */
  {
    short: 'DCW',
    fullName: 'DOMESTIC COLD WATER ',
    color: '#7bbfea',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'DHW',
    fullName: 'DOMESTIC HOT WATER ',
    color: '#aa2116',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'DHR',
    fullName: 'DOMESTIC HOT WATER RETURN',
    color: '#d71345',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'CSW',
    fullName: 'COLD SOFT WATER',
    color: '#90d7ec',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'HT',
    fullName: 'HEAT TRACE HOT WATER ',
    color: '#f58220',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'NPW',
    fullName: 'NON-POTABLE WATER ',
    color: '#b2d235',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'DI',
    fullName: 'DEIONIZED WATER ',
    color: '#007d65',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'RO',
    fullName: 'REVERSE OSMOSIS WATER',
    color: '#9b95c9',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'PW',
    fullName: 'PURE WATER ',
    color: '#11264f',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'PCW',
    fullName: 'PROCESS COLD WATER',
    color: '#008792',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'PHW',
    fullName: 'PROCESS HOT WATER',
    color: '#840228',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'PHR',
    fullName: 'PROCESS HOT WATER RETURN',
    color: '#f391a9',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'LHR',
    fullName: 'LAB HOT WATER',
    color: '#892f1b',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'TF',
    fullName: 'TRAP FILLER LINE',
    color: '#cbc547',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'TW',
    fullName: 'TEMPERED WATER',
    color: '#5e7c85',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'SAN',
    fullName: 'WASTE OR SOIL',
    color: '#563624',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'SD',
    fullName: 'SUBSOIL DRAIN',
    color: '#8e3e1f',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'S',
    fullName: 'STORM',
    color: '#63434f',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'OD',
    fullName: 'OVERFLOW DRAIN',
    color: '#121a2a',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'FM',
    fullName: 'FORCE MAIN',
    color: '#769149',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'CWW',
    fullName: 'CLEARWATER WASTE ',
    color: '#ffd400',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'IW',
    fullName: 'INDIRECT WASTE ',
    color: '#726930',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'GW',
    fullName: 'GREASE WASTE',
    color: '#845538',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'CRW',
    fullName: 'CORROSION RESISTANT WASTE',
    color: '#74787c',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'STM',
    fullName: 'STORM SEWER ',
    color: '#72777b',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'PD',
    fullName: 'PUMP DISCHARGE ',
    color: '#1b315e',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'F',
    fullName: 'FIRE MAIN',
    color: '#444693',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'CW',
    fullName: 'DOMESTIC COLD WATER ',
    color: '#006c54',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'HW',
    fullName: 'HOT WATER ',
    color: '#411445',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'AV',
    fullName: 'ACID VENT',
    color: '#ffc20e',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'OV',
    fullName: 'OIL VENT',
    color: '#45b97c',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'OW',
    fullName: 'OIL WASTE',
    color: '#817936',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'DT',
    fullName: 'DRAIN TILE',
    color: '#46485f',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'DS',
    fullName: 'STORM DRAINAGE',
    color: '#63434f',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  {
    short: 'ODS',
    fullName: 'STORM DRAINAGE OVER FLOW',
    color: '#5f5d46',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.W,
  },
  /** FLUID - GASES */
  {
    short: 'AR',
    fullName: 'ARAGON',
    color: '#130c0e',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'CA',
    fullName: 'COMPRESSED AIR',
    color: '#00ae9d',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'CO2',
    fullName: 'CARBON DIAOXIDE ',
    color: '#d71345',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'H2',
    fullName: 'HYDROGEN',
    color: '#afdfe4',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'LA',
    fullName: 'LABORATORY COMPRESSED AIR ',
    color: '#1d953f',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'LN2',
    fullName: 'LIQUID NITROGEN',
    color: '#585eaa',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'LVAC',
    fullName: 'LABORATORY VACCUM',
    color: '#da765b',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'MA',
    fullName: 'MEDICAL COMPRESSED AIR ',
    color: '#f173ac',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'MV',
    fullName: 'MEDICAL VACCUM ',
    color: '#aa2116',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'NG',
    fullName: 'NATURAL GAS ',
    color: '#411445',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'N2',
    fullName: 'NITROGEN',
    color: '#f58220',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'N2O',
    fullName: 'NITROUS OXIDE ',
    color: '#c37e00',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'O2',
    fullName: 'OXYGEN',
    color: '#f15a22',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'WAGD',
    fullName: 'WASTE ANESTHETIC GAS DISPOSAL',
    color: '#ea66a6',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  {
    short: 'WGE',
    fullName: 'WASTE GAS EVACUATION ',
    color: '#f58f98',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.G,
  },
  /** FLUID - VENT */
  {
    short: 'V',
    fullName: 'VENT',
    color: '#1a2933',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.V,
  },
  {
    short: 'CWV',
    fullName: 'CLEARWATER VENT',
    color: '#65c294',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.V,
  },
  {
    short: 'CRV',
    fullName: 'CORROSON RESISTANT VENT',
    color: '#1d953f',
    category: FITTING_TYPE.P,
    fluidType: FLUID_TYPE.V,
  },
];

export const TKSYSTEM_SPEC: TKSystem[] = [...DUCT_SYSTEMS, ...PIPE_SYSTEMS];

/**
 * @deprecated
 * @param shorts
 * @returns
 */
export const getSystemDefineFromShort = (shorts: string[]): TKSystem[] => {
  const unknown: TKSystem = {
    short: 'UNO',
    fullName: 'UNKNOWN',
    color: '#000000',
    category: FITTING_TYPE.D,
  };
  return shorts.map((short) => {
    const comparor = (tksys: TKSystem) => tksys.short === short;
    const system = TKSYSTEM_SPEC.find(comparor);
    if (!system) {
      console.warn(`>> couldnt find system in definition: ${short}`);
    }
    if (system) return system;
    // try without `/`
    const comparor2 = (tksys: TKSystem) => removeSlashes(tksys.short) === short;
    const systemNoSlash = TKSYSTEM_SPEC.find(comparor2);
    if (systemNoSlash) return { ...systemNoSlash, short };
    // unknow system to deinfe in backend ...
    return { ...unknown, short, fullName: `UNKNOWN-${short}` };
  });
};
