import { FittingDimension, FITTING_DUCT as DUCT, GO } from '.';

/**
 * Handling dimension cache during the symbol addtion and corrections
 */
type FDCONFIG = { [key: string]: FittingDimension };

const defaultValues = {
  angle: '90',
  shape: 'round',
};

/**
 * runtime inputs cache for different fittings
 */
export const dimensions: FDCONFIG = {
  // runtime to add it...
  [DUCT.BALANCING_DAMPER]: defaultValues,
  [DUCT.ELBOW]: defaultValues,
  [DUCT.CENTER_LINE_REDUCER]: defaultValues,
  [DUCT.ECCENTRIC_REDUCER]: defaultValues,
  [DUCT.Y_JUNCTION]: defaultValues,
};

/**
 * cache fitting details
 * @param type fitting type
 * @param dimension fitting details
 */
export const setDimensionBy = (type: string, dimension: FittingDimension) => {
  dimensions[type] = dimension;
};

/**
 * default & cached dimension values
 * @param type symbol type
 * @returns
 */
export const getDimensionBy = (type: string) => dimensions[type] || {};

const systemByEntity: GO = {};

/**
 * Cache system value for each fitting type to initial system select default value
 *
 * FIXME: Regardless the `type`, save it with latest system - @2024/01/24
 *
 * @param type entity type like `ELBOW` ...
 * @param system
 */
export const setSystemBy = (type: string, system: string) => {
  systemByEntity['default'] = system;
};

/**
 * FIXME: Regardless the `type`, save it with latest system - @2024/01/24
 *
 * @param type
 * @returns
 */
export const getSystemBy = (type: string): string => {
  if (!type) return '';
  return systemByEntity['default'] || '';
};

/**
 * Get last used dimension value, if no cached one found, use hard-coded `dimensions` setting
 *
 * @param ductType
 * @returns
 */
export const lastShapeFor = (ductType: string | undefined) => {
  if (!ductType) return '';
  const values = getDimensionBy(ductType.toUpperCase());
  return (values['shape'] as string) || '';
};
