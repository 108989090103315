import * as _ from 'lodash-es';

import {
  BPPage,
  GenericObject,
  TKSystem,
  FITTING_TYPE,
  FittingMeta,
} from '../types';

import { JobState } from './common';

// === bursh settings cache ===

const brushCorrectionInit = {
  symbolType: 'DUCT',
  selectedSystem: '',
  renderColor: 'red',
};

export const getBrushCorrectSetting = () => brushCorrectionInit;

export const cacheBrushCorrectSetting = (setting: any) => {
  _.merge(brushCorrectionInit, { ...setting });
};

// ==== total correction settings ===

type CorrectionSetting = {
  category: string;
  items: FittingMeta[];
  selectedItem: FittingMeta | null;
  fieldToSort: string;
  order: number;
  allCompleted: boolean;
  userCompleted: boolean;
  allSymbolsDisplay: boolean;
};

const tableCorrectionInit = {
  category: FITTING_TYPE.D as string,
  items: [],
  selectedItem: null,
  fieldToSort: '',
  order: 0,
  /** default value for: Show Completed checkbox */
  allCompleted: true,
  /** defautl value for: Show user cofirmed checkbox */
  userCompleted: false,
  /** if display all symbols in drawing */
  allSymbolsDisplay: false,
};

/**
 * this will change
 */
const totalCorrectionSetting: CorrectionSetting = { ...tableCorrectionInit };

/**
 * @deprecated
 * @returns
 */
export const getTotalCorrectSetting = () => totalCorrectionSetting;

/**
 * @deprecated
 * Temporarily the total correction conditions, and used in symobos store refresh
 *
 * @param setting
 */
export const cacheTotalCorrectSetting = (setting: CorrectionSetting) => {
  _.merge(totalCorrectionSetting, { ...setting });
};

/**
 * @deprecated
 * Reset the correction conditions to initial
 */
export const resetTotalCorrectionSetting = () => {
  _.merge(totalCorrectionSetting, tableCorrectionInit);
};

// ==== end of total correction settings

// ==== cache touched symbols ====

const touchedSymbols: { [pageId: string]: Set<string> } = {};

/**
 * @deprecated
 *
 * @param pageId
 * @param symbolType
 * @param symbolIndex
 * @returns
 */
export const touchSymbolOn = (
  pageId: string,
  symbolType: string,
  symbolIndex: number,
) => {
  const symbols = touchedSymbols[pageId];
  if (symbols) return symbols.add(`${symbolType}_${symbolIndex}`);
  // create a new set
  const firstSymbol = `${symbolType}_${symbolIndex}`;
  touchedSymbols[pageId] = new Set([firstSymbol]);
};

/**
 * @deprecated
 *
 * @param pageId
 * @param symbolType
 * @param symbolIndex
 * @returns
 */
export const isSymbolTouched = (
  pageId: string,
  symbolType: string,
  symbolIndex: number,
): boolean => {
  const symbols = touchedSymbols[pageId];
  if (!symbols) return false;
  const key = `${symbolType}_${symbolIndex}`;
  return symbols.has(key);
};

/**
 * @deprecated
 *
 * @param pageId
 * @param symbolType
 * @param symbolIndex
 * @returns
 */
export const cleanTouchedSymbol = (
  pageId: string,
  symbolType: string,
  symbolIndex: number,
) => {
  const symbols = touchedSymbols[pageId];
  if (!symbols) return;
  const key = `${symbolType}_${symbolIndex}`;
  symbols.delete(key);
};

// ==== end of touched symbols cache ====

const currentPage: BPPage[] = [];

/**
 * cache current page for duct modal use
 *
 * @param page
 */
export const cacheCurrentPage = (page: BPPage | undefined) => {
  currentPage.length = 0;
  page && currentPage.push(page);
};

export const getCurrentPage = () =>
  currentPage.length ? currentPage[0] : null;

/**
 * cache page card openess cache, in order to remember file details page navigation audit!
 * @2023/05/03
 */
// const historyStates: State[] = [];
// export const addHistoryState = (state: State) => historyStates.push(state);
// export const getHistoryState = () => historyStates.slice(-1)[0] || {};

/**
 * user picked systems by category `FITTING_TYPE`
 */
const systemSelectedItems: { [type: string]: TKSystem[] } = {};

export const cacheSystemsBy = (type: string, systems: TKSystem[]) => {
  systemSelectedItems[type] = systems;
};

/**
 * Get user selected systems
 * @param type fitting type
 * @returns
 */
export const getCachedSystemsBy = (type: string) => {
  return systemSelectedItems[type] || [];
};

// ============= cache symbol details  ===================

// TODO: consider use `zstore` - @2023/08/01
const symbolDetails: { [id: string]: GenericObject } = {};

/**
 * Cache newly created symbol properties with symbol id(feature id) at `useSymbols`.
 *
 * Two places to use:
 * 1. possibly used to compare with changes
 * 2. correction modal to restore symbol properties
 *
 * NOTE: this is usefull to access in correction modal,
 * cause hard to access map instance...
 *
 * @param id symbol/feature id
 * @param detail properties
 */
export const cacheSymbolDetail = (id: string, detail: GenericObject) => {
  symbolDetails[id] = detail;
};

export const clearSymbolDetail = (id: string) => {
  delete symbolDetails[id];
};

export const clearSymbolsDetail = (ids: string[]) => {
  ids.forEach((id) => delete symbolDetails[id]);
};

export const getSymbolDetailBy = (id: string) => {
  return symbolDetails[id] || {};
};

export const getCachedSymbols = () => {
  return symbolDetails;
};

// ============= job status cache handling =======================
// TODO: move this to zstore...
export const jobStatusCache: { [pageId: string]: JobState } = {};

export const clearJobStatus = (pageId: string) => {
  delete jobStatusCache[pageId];
};

/**
 * Cache job status by page into memory
 * @param pageId
 * @param job
 */
export const cacheJobStatus = (pageId: string, job: JobState) => {
  jobStatusCache[pageId] = job;
};

/**
 * Get job status by page from memory
 * @param pageId key of job cache
 * @returns
 */
export const getJobStatus = (pageId?: string): JobState => {
  const empty = { status: null, jobId: null };
  if (!pageId) return empty;
  return jobStatusCache[pageId] || empty;
};

// ============== pages caches for project =======================
const pagesCache: { [projectId: string]: BPPage[] } = {};

export const cachePages = (projectId: string, pages: BPPage[]) => {
  pagesCache[projectId] = pages;
};

export const getProjectPages = (projectId: string) => {
  return pagesCache[projectId] || [];
};

// ============== slider page index cache ======================
const projectSlider = { page: 0 };
export const cacheSliderPage = (index: number) => (projectSlider.page = index);
export const getSliderPageIndex = () => projectSlider.page;
export const resetSliderPage = () => (projectSlider.page = 0);

// =========== drawing lock status cache to facilitate multiple page switching =======================
// =========== event after the browser closed and reopen ========================
export const cacheLockedPage = (pageId: string) => {
  localStorage.setItem(`tk_locked_page_${pageId}`, '1');
};

export const isPageLocked = (pageId: string) => {
  const key = `tk_locked_page_${pageId}`;
  return !!localStorage.getItem(key);
};

export const clearCachedPage = (pageId: string) => {
  const key = `tk_locked_page_${pageId}`;
  localStorage.removeItem(key);
};
