/**
 * Project module level store, share some data inside project module and its sub page
 * @2023/05/05
 */

// import { nanoid } from 'nanoid';
import { create } from 'zustand';

import {
  CATALOGUE_TYPE as CTLG,
  FittingMeta,
  TKItemCatalog,
  itemCatalogueToFittingMeta as catalogueToMeata,
} from '../types';

type ItemCatalogueStore = {
  /** if catalogue loaded */
  cataloguesAvailable: boolean;
  allCatalogues: FittingMeta[];
  fittingCatalogues: FittingMeta[];
  pipeCatalogus: FittingMeta[];
  /** save catalogues definition */
  cacheFittingCatalogues: (items: TKItemCatalog[]) => void;
};

/**
 * == NOT IN USE ==
 * Cache catalogues definition as a global state
 * @deprecated in favor of `itemCatalogueStore` hook
 */
export const useCatalogueStore = create<ItemCatalogueStore>((set, get) => ({
  cataloguesAvailable: false,
  allCatalogues: [],
  fittingCatalogues: [],
  pipeCatalogus: [],
  cacheFittingCatalogues: (catalogues: TKItemCatalog[]) =>
    set(() => {
      const items = catalogues.map((item) => catalogueToMeata(item));
      const fittings = items.filter((item) => item.rawType === CTLG.F);
      const pipes = items.filter((item) => item.rawType === CTLG.L);
      return {
        cataloguesAvailable: true,
        allCatalogues: items,
        fittingCatalogues: fittings,
        pipeCatalogus: pipes,
      };
    }),
}));

type SocketStore = {
  socket: WebSocket | null;
  failureCount: number;
  /**
   * add failure count by 1 in case of error captured
   * @returns
   */
  addFailureCount: () => void;
  /**
   * Act as a re-connect trigger
   * @returns
   */
  clearFailureCount: () => void;
  /**
   * Save socket instance globally
   * @param ws
   * @returns
   */
  setSocket: (ws: WebSocket | null) => void;
  /**
   * close connection and destroy the socket
   */
  cleanup: () => void;
};

/**
 * Global websocket connection, to decide if need to reconnect in case of failure
 * @date 2024/04/29
 */
export const useWSStore = create<SocketStore>((set, get) => ({
  socket: null,
  failureCount: 0,
  addFailureCount: () =>
    set((state) => ({ failureCount: state.failureCount + 1 })),
  clearFailureCount: () => set({ failureCount: 0 }),
  setSocket: (ws: WebSocket | null) => set({ socket: ws }),
  cleanup: () => {
    const ws = get().socket;
    ws?.close();
    set({ socket: null });
  },
}));

/**
 * TODO: prepare for editor history management
 */
// export type SymbolsEditRecord = {
//   lastChangeAction: string;
//   lastChangeSymbol: BPSymbol;
//   currentSymbol: BPSymbol;
// };

type SymbolsEditStore = {
  /** if the drawing have symbol changes */
  changed: boolean;
  /** change for page - @2024/02/09 */
  changeForPages: { [pageId: string]: boolean };
  /** Update changed state by page - @2024/02/09 */
  setChangeBy: (pageId: string) => void;
  /** check if the page has `changed` */
  getChangeBy: (pageId: string) => boolean;
  /** set global state for symbol edit change */
  setChanged: () => void;
  /** clear global symbol change */
  clearChanges: () => void;
};

/**
 * Global pages change state
 */
export const useSymbolsEditStore = create<SymbolsEditStore>((set, get) => ({
  changed: false,
  changeForPages: {},
  getChangeBy: (pageId: string) => {
    const changes = get().changeForPages;
    return !!changes[pageId];
  },
  setChangeBy: (pageId: string) =>
    set((state) => {
      const changes = { ...state.changeForPages, [pageId]: true };
      return { changed: true, changeForPages: changes };
    }),
  setChanged: () => set({ changed: true }),
  clearChanges: () => set({ changed: false, changeForPages: {} }),
}));
