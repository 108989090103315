/**
 * Global dom custom event dispatcher
 * @date 2024/04/07
 */

import { getDebounceFunction } from '@/utils';

import { GO, FILTERS, BPSymbol } from '../types';
import { TKEvent } from '../types/events';
import { FEATUREMODES } from '../types/options';

/**
 * Helper function for Commander
 *
 * @param eventName
 * @param detail
 * @param lazy
 * @param delay
 * @returns
 */
const domEventDispather = (
  eventName: string,
  detail: any = null,
  lazy?: boolean,
  delay?: undefined | number,
) => {
  const doIt = () => {
    const ctmEvt = new CustomEvent(eventName, { detail });
    document.dispatchEvent(ctmEvt);
  };
  // do it now!
  if (!lazy) return doIt();
  setTimeout(doIt, delay);
};

/**
 * Global Dom Custom Event Dispatcher,
 * these events will be processed at `useDomEvents` hook!
 */
export const Commander = {
  doEscapeKeyPressed: () => {
    document.dispatchEvent(new KeyboardEvent('keydown', { key: 'Escape' }));
  },
  doStopPooling: (pageId: string) => {
    console.info(`## one job done, stop polling!`);
    domEventDispather(TKEvent.STOPPOLLING, pageId, true);
  },
  doFlyToFeature: (id: string) => {
    domEventDispather(TKEvent.FLYTOFEATURE, id, true, 300);
  },
  doFlyToFeatureOnReview: (symbolId: string) => {
    // fly to new symbol
    domEventDispather(TKEvent.FLYTOFEATUREONLEFT, symbolId);
  },
  doFilterSymbols: (type: string) => {
    domEventDispather(TKEvent.FILTERSYMBOLS, type);
  },
  doShowAllSymbols: () => {
    domEventDispather(TKEvent.FILTERSYMBOLS, FILTERS.ALL);
  },
  doShowTableSymbols: (symbols: BPSymbol[]) => {
    domEventDispather(TKEvent.SYNC_SYMBOLS_FROM_TABLE, symbols);
  },
  doReverseSymbols: (checked: boolean) => {
    domEventDispather(TKEvent.REVERSYMBOLS, checked);
  },
  doToggleSizeText: (checked: boolean) => {
    domEventDispather(TKEvent.REVERSESIZEDISPLAY, checked);
  },
  doZoomOut: () => {
    domEventDispather(TKEvent.ZOOMOUT, 'smaller map');
  },
  doZoomIn: () => {
    domEventDispather(TKEvent.ZOOOMIN, 'larger map');
  },
  doZoomBy: (level: string) => {
    domEventDispather(TKEvent.ZOOMBY, level);
  },
  doFeatureDelete: (id: string) => {
    domEventDispather(TKEvent.FEATUREDELETED, id);
  },
  /** moved from `useMapCallback` hook to here - @2024/05/28 */
  doFeatureCorrectHandler: (
    id: string | undefined,
    type: string,
    category: string,
  ) => {
    domEventDispather(TKEvent.OPEN_TOTAL_TABLE, { id, type, category });
  },
  doFeatureModifyMode: () => {
    domEventDispather(TKEvent.MODIFYFEATUREMODE, FEATUREMODES.M);
  },
  doFeatureTranslateMode: () => {
    domEventDispather(TKEvent.MOVEFEATUREMODE, FEATUREMODES.T);
  },
  doFeatureAutoMode: () => {
    domEventDispather(TKEvent.AUTOFEATUREMODE, FEATUREMODES.A);
  },
  /**
   * NOTE: where to use this?
   */
  doHoldOffSaving: () => {
    domEventDispather(TKEvent.CANCEL_SAVING_CHANGES);
  },
  doScheduledAutoSaving: () => {
    domEventDispather(TKEvent.SYNCBATCH_IN_STORE);
  },
  /**
   * Update symbol in map & symbol store
   * @param detail corrected symbol
   */
  doSilentCorrection: (detail: GO) => {
    domEventDispather(TKEvent.CORRECTSYMBOLSILENT, detail);
  },
  /**
   * @deprecated
   * This will trigger an immediate saving and total symbols refreshing from backend
   */
  doSilentSavingNow: () => {
    // this is a relay event, to display spinner at save button
    domEventDispather(TKEvent.SAVETOTALCORRECTIONS, true);
    domEventDispather(TKEvent.CANCEL_SAVING_CHANGES);
  },
  doSymbolConfirm: (id: string) => {
    domEventDispather(TKEvent.CONFIRMSYMBOL, id);
  },
};

/**
 * to throttle and send event...with list of symbols
 */
export const lazySynchronizeSymbolsChange = getDebounceFunction((symbols) => {
  Commander.doShowTableSymbols(symbols as BPSymbol[]);
  400; // after animation completion
});
