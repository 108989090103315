import * as _ from 'lodash-es';

import { moveElementToBack, moveElementBackOF } from '@/utils';

import { GO } from './common';
import {
  FIELD_D1,
  FIELD_D2,
  FIELD_W1,
  FIELD_H1,
  FIELD_W2,
  FIELD_H2,
  FIELD_ID,
  FIELD_ST,
  FIELD_SN,
  DEFAULT_EMPTIY_OPT,
  DEFAULT_ALL_SHORT,
  LENGTH_STR_FIELD as LSF,
  checkFieldBelongBy,
  stringTypeFields,
} from './dimension';
import { TKItemProperty } from './entity';
import {
  findEntityTypesFromSymbols,
  getCommonFieldsOfFittings,
  findFieldsByType,
  findItemPropertyBy,
} from './fittings';
import { BPSymbol, SymbolFindResult as SFR } from './symbols';
import { completionCalculator } from './versionlizer';

export type CommonCell = {
  /** current field */
  field: string;
  /** if this column is sortable */
  sortable?: boolean;
};

export type HeaderCell = {
  name: string;
} & CommonCell;

export type SymbolCell = {
  /** symbol internal id */
  id: string;
  /** `input` if true, otherwise plain div will be built  */
  editable: boolean;
  value: string | number;
  /** title for hover */
  title?: string;
  /** value type: 'string' | 'bar' | 'image' | 'button' */
  type: string;
  /** if this symbol validated manually */
  confirmed?: boolean;
  /** all dimension fields in table, will attach to `completion` cell */
  fields?: string[];
  /** symbol type: like `ELBOW` */
  class: string;
  /** if available to edit, determine `disabled` attribute of input */
  invisible?: boolean;
  /** allow to focus on tab change, false by default */
  focusable?: boolean;
  /** item property definition */
  itemProperty?: TKItemProperty | null;
  /** drawing unit */
  unit?: string;
} & CommonCell;

export type SymbolRow = SymbolCell[];

const headFields = ['id', 'type'];
const operationFields = ['thumbnail', 'completion', 'user confirm'];

/**
 * Check length field to re-order the cell list.
 *
 * FIXME: Move `LSF` to the back if found
 * with the request of Simon @2024/03/15
 *
 * @param fields
 * @param cells
 */
const lengthFieldToBack = (fields: string[], cells: CommonCell[]) => {
  const hasLSF = fields.includes(LSF);
  if (hasLSF) {
    const strLengthPos = cells.findIndex((sf) => sf.field == LSF);
    const [strLengthField] = cells.splice(strLengthPos, 1);
    cells.push(strLengthField);
  }
};

/**
 * find out the commone fields from selecte item and filtered symbols
 *
 * INCLUDE FIELD: `length_unit_str` that used to display only, but,
 *
 * DOES NOT INCLUDE FIELD: `system` & `shape` & `length_1`,
 *
 * NOTE: both `length_1` and `length_unit_str` are NOT EDITABLE!!
 */
export const getCommonFields4Table = (smbols: SFR[]) => {
  const details = smbols.map((smbl) => smbl.detail as GO);
  const { types } = findEntityTypesFromSymbols(details, true);
  // FIXME: include length str field in table regarding issue:
  // https://taksoai.atlassian.net/browse/TAK-520
  // @2024/03/14
  const fields = getCommonFieldsOfFittings(types, false);

  // FIXME: reorder fields by swapping `height_1` with `width_1`, to make `width_1` first always!
  // https://taksoai.atlassian.net/browse/TAK-519
  // @2024/03/15

  // First, prepare an empty fields to put ordered fields
  const reOrderedFields = [...fields];
  const wIndex = fields.indexOf(FIELD_W1);
  const hIndex = fields.indexOf(FIELD_H1);
  if (wIndex > -1 && hIndex > -1) {
    const swappedFields = moveElementBackOF(fields, hIndex, wIndex);
    reOrderedFields.length = 0; // clear first
    reOrderedFields.push(...swappedFields);
  }
  // Second, continue checking w2, h2 ...
  const w2Index = reOrderedFields.indexOf(FIELD_W2);
  const h2Index = reOrderedFields.indexOf(FIELD_H2);
  if (w2Index > -1 && h2Index > -1) {
    const swappedFields = moveElementBackOF(reOrderedFields, h2Index, w2Index);
    reOrderedFields.length = 0; // clear first
    reOrderedFields.push(...swappedFields);
  }
  // Third, continue checking `diameter_2`
  const d1Index = reOrderedFields.indexOf(FIELD_D1);
  const d2Index = reOrderedFields.indexOf(FIELD_D2);
  if (d1Index > -1 && d2Index > -1) {
    const swappedFields = moveElementBackOF(reOrderedFields, d2Index, d1Index);
    reOrderedFields.length = 0; // clear first
    reOrderedFields.push(...swappedFields);
  }
  // Last, move str length to the end of fields;
  const strLengthIndex = reOrderedFields.indexOf(LSF);
  if (strLengthIndex > -1) {
    const swappedFields = moveElementToBack(reOrderedFields, strLengthIndex);
    reOrderedFields.length = 0; // clear first
    reOrderedFields.push(...swappedFields);
  }

  // Last and last, for equipment coloumn, move `identifier` to the end of `subtype`
  // FIXME: https://taksoai.atlassian.net/browse/TAK-700
  // Rearrange order of symbols for equipment on review panel (equipment)
  const identifierIndex = reOrderedFields.indexOf(FIELD_ID);
  const subtypeIndex = reOrderedFields.indexOf(FIELD_ST);
  if (identifierIndex > -1 && subtypeIndex > -1) {
    const swappedFields = moveElementBackOF(
      reOrderedFields,
      identifierIndex,
      subtypeIndex,
    ).filter((f) => f !== FIELD_SN);
    reOrderedFields.length = 0; // clear first
    reOrderedFields.push(...swappedFields);
  }

  // == filter out some uneceessary fields ==
  return _.difference(reOrderedFields, ['length_1', 'system', 'shape']);
};

export const completionReducer = (total: number, prev: BPSymbol) => {
  const fieldsOfSymbol = findFieldsByType(prev.class);
  return total + completionCalculator(prev, fieldsOfSymbol);
};

/**
 * FIXME: any problem?
 * An unifed * general function to figure out drawing completion?
 * @param symbols
 * @returns
 */
export const calculateFloatCompletion = (symbols: BPSymbol[]) => {
  const completionSum = symbols.reduce(completionReducer, 0);
  // console.log({ completionSum });
  const floatCompletion = (completionSum / symbols.length).toFixed(1);
  return floatCompletion;
};

/**
 * Build cells of each symbol row
 *
 * @param symbol
 * @param fields
 * @param unit
 * @returns
 */
export const rowBuilder = (
  symbol: SFR,
  fields: string[],
  unit: string,
): SymbolCell[] => {
  // console.log({ symbol });
  // console.log(`>> shap: ${symbol.detail['shape']} `);
  const shape = symbol.detail['shape'];
  // empty row
  const cells: SymbolCell[] = [];
  const shortify = (str: string) =>
    str.length > 6 ? `${str.substring(0, 6)}...` : str;
  // id:
  cells.push({
    id: symbol.id as string,
    editable: false,
    field: 'id',
    value: `#${symbol.index}`,
    type: 'string',
    class: symbol.class,
  });
  // type:
  cells.push({
    id: symbol.id as string,
    editable: false,
    field: 'type',
    value: shortify(symbol.class),
    title: symbol.class,
    type: 'string',
    class: symbol.class,
  });

  // === dimensions: ===
  fields.forEach((f) => {
    const type = stringTypeFields.includes(f) ? 'string' : 'number';
    const origValue = symbol.detail?.[f];
    cells.push({
      id: symbol.id as string,
      editable: f !== LSF, // true, except for LSF
      field: f,
      value: origValue,
      type,
      sortable: true,
      class: symbol.class,
      title: f,
      invisible: !checkFieldBelongBy(shape, f),
      itemProperty: findItemPropertyBy(symbol.class, f),
      unit,
    });
  });

  // system cell
  cells.push({
    id: symbol.id as string,
    editable: true,
    field: 'system',
    value: symbol.detail?.system as string,
    type: 'string',
    class: symbol.class,
    title: 'system',
  });

  // FIXME: with the request of simon to
  // reorder the length field to the end of system field
  // @2024/03/15
  lengthFieldToBack(fields, cells);

  // thumbnail cell
  cells.push({
    id: symbol.id as string,
    editable: false,
    field: 'thumbnail',
    value: symbol.thumbnail || symbol.icon || '',
    type: 'image',
    class: symbol.class,
  });

  // completion
  cells.push({
    id: symbol.id as string,
    editable: false,
    field: 'completion',
    value: completionCalculator(symbol, fields),
    type: 'bar',
    class: symbol.class,
    sortable: true,
    fields, // save it for `completion` recalculation in correction edit
  });

  // operations, confirm or delete
  cells.push({
    id: symbol.id as string,
    editable: false,
    field: 'operation',
    value: 'confirm|delete',
    type: 'button',
    class: symbol.class,
    confirmed: symbol.confirmed,
  });

  return cells;
};

/**
 * Build rows from filtered symbols and selected item type fields
 *
 * @param symbols
 * @param fields fields from `selectedItem`
 * @param sortBy header field to sort
 * @param order ascending or descending
 * @param showAllCompleted if display completed, true(checked) by default
 */
export const buildRowsAndSyncToDrawing = (
  symbols: SFR[],
  sortBy: string,
  order = 0,
  showAllCompleted: boolean,
  userConfirmed: boolean,
  systemShort: string,
  unit: string,
): { row: SymbolRow; symbol: BPSymbol }[] => {
  const fields = getCommonFields4Table(symbols);
  const toNum = (v: string) => Number(v);
  const valueOf = (s: SFR, f: string) => toNum(s.detail?.[f]);
  const isCompletionField = sortBy === 'completion';
  const evaluater = isCompletionField
    ? (smb: SFR) => completionCalculator(smb, fields)
    : valueOf;
  const ascComparor = (a: SFR, b: SFR) =>
    evaluater(a, sortBy) - evaluater(b, sortBy);
  const desComparor = (a: SFR, b: SFR) =>
    evaluater(b, sortBy) - evaluater(a, sortBy);

  // CASE 1: all the symbols except for `confirmed` and `completed`, smallest scope
  const defaultFilter = (smbl: SFR) => !smbl.confirmed && !smbl.completed;

  // CASE 2: all the symbols except for the `completed`!, bigger scope
  const confirmedFilter = (smbl: SFR) => !smbl.completed || smbl.confirmed;

  // CASE 3: all the symbols, biggest scope if checked
  const completedFilter = (smbl: SFR) => true;

  // system filter - safe conversion `short` code first
  const lowerCode = (short: string) =>
    short.replace('/', '').toLocaleLowerCase();
  const safeSystemCode =
    systemShort === DEFAULT_EMPTIY_OPT ? '' : lowerCode(systemShort);
  const systemFilter = (smbl: SFR) => {
    if (safeSystemCode === DEFAULT_ALL_SHORT) return true;
    return lowerCode(smbl.system) === safeSystemCode;
  };

  // == First going through those checkboxes with pipe approach
  // FIXME: use filter pipe lines to filter out the symbols
  // @2024/04/11
  const confirmedPipe = (smbl: SFR) => (userConfirmed ? true : !smbl.confirmed);
  const completedPipe = (smbl: SFR) =>
    showAllCompleted ? true : !smbl.completed;
  const symbolsAfterPipes = symbols.filter(confirmedPipe).filter(completedPipe);
  // log(`>>> Symbols after pipes: ${symbolsAfterPipes.length}`);
  // console.log(symbolsAfterPipes);

  // == Filter symbols with `system`
  const symtemedSymbols = symbolsAfterPipes.filter(systemFilter);

  // == Build row by details ===
  const iterator = (symbol: SFR) => {
    return {
      row: rowBuilder(symbol, fields, unit),
      symbol, // need this to check if `shape` is defined from `detail` of symbol
    };
  };

  // == Last step: processing `order` setting, no order by default
  if (order == 0) return symtemedSymbols.map(iterator);

  // order could be '1' or '-1'
  const sortor = order == 1 ? ascComparor : desComparor;
  return symtemedSymbols.sort(sortor).map(iterator);
};

/**
 * header structure may like this:
 * head fields - dimension fields - operation fields
 *
 * FIXME: DO NOT USE `length_1`, use `length_unit_str` instead
 *
 * @param dimensions
 * @returns
 */
export const dynaHeaderFields = (dimensions: string[]): HeaderCell[] => {
  const fToH = (field: string) => ({ field, sortable: false });
  const f2H = (field: string) => ({ field, sortable: true });

  const sortableFields = headFields
    .map(fToH)
    .concat(dimensions.map(f2H))
    .concat({ field: 'system', sortable: false });

  // FIXME: to put str_length back - @2024/03/15
  lengthFieldToBack(dimensions, sortableFields);

  const shortifyFieldName = (field: string) => {
    if (field === LSF) return 'Length str';
    if (field === FIELD_D1) return 'Diame...1';
    if (field === FIELD_D2) return 'Diame...2';
    return _.startCase(field);
  };

  return sortableFields
    .concat(fToH(operationFields[0])) // thumbnail
    .concat(f2H(operationFields[1])) // completion
    .concat(fToH(operationFields[2])) // user confirm
    .map((h) => ({
      ...h,
      // special case for length str field:
      name: shortifyFieldName(h.field),
    }));
};

export const checkIfSymbolCompletedBy = (
  detail: GO,
  commenFields: string[],
): boolean => {
  const symbol = { detail } as BPSymbol;
  // console.log(`>>> to figure out if completed with detail:`);
  // console.log(detail);
  const fieldsNoSystem = commenFields.filter((f) => f !== 'system');
  const completion = completionCalculator(symbol, fieldsNoSystem);
  // FIXME: sometimes this value could be 99, so treat it as `completed`
  // @2023/11/07
  return completion >= 99;
};

// export const
