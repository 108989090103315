import { ChangeEvent } from 'react';

export type EventHandler = (event: any | undefined) => void;

export type EventConfigCTX<T> = {
  [event: string]: EventHandler | T;
  context: T;
};

export type EventConfig = {
  [event: string]: EventHandler;
};

/**
 * Document or Window event registration
 */
const globalEventRegistration: EventConfig = {};

export const addEventHandler = (
  target: EventTarget,
  event: string,
  handler: EventHandler,
) => {
  target.addEventListener(event, handler);
  globalEventRegistration[event] = handler;
};

export const addDomEventHandler = (event: string, handler: EventHandler) => {
  document.addEventListener(event, handler);
  globalEventRegistration[event] = handler;
};

export const hasEventHandler = (event: string) => {
  const handler = globalEventRegistration[event];
  return !!handler;
};

export const removeEventHandler = (
  target: EventTarget,
  event: string,
  handler: EventHandler,
) => {
  target.removeEventListener(event, handler);
  delete globalEventRegistration[event];
};

export const removeDomEventHandler = (event: string, handler: EventHandler) => {
  document.removeEventListener(event, handler);
  delete globalEventRegistration[event];
};

export type TKInputEvent = ChangeEvent<HTMLInputElement | HTMLSelectElement>;

export type TKSelectEvent = ChangeEvent<HTMLSelectElement>;

export enum TKEvent {
  /** browser inactive */
  BLUR = 'blur',
  CLICK = 'click',
  FOCUS = 'focus',
  /** capture `Meta`, `Ctrl`, `Escape`, `Delete` */
  KEYDOWN = 'keydown',
  KEYUP = 'keyup',
  CONTEXTMENU = 'contextmenu',
  POINTERDOWN = 'pointerdown',
  POINTERUP = 'pointerup',
  POINTERMOVE = 'pointermove',
  ZOOMLEVELCHANGE = 'change:resolution',
  ZOOMOUT = 'zoomOut',
  ZOOOMIN = 'zoomIn',
  ZOOMBY = 'zoomBy',
  /** === draw a new fitting(dot) on map */
  NEWELEMENT = 'newElement',
  /** === draw a new length(line) on map */
  NEWLINE = 'newLine',
  /** correct selected feature from original correction modal @deprecated */
  CORRECTELEMENT = 'correctElement',
  /** correct symbol in total correction table */
  CORRECTSYMBOLSILENT = 'correctSymbolSilent',
  /** confirm symbol, mark symbol property: user_validated:1 */
  CONFIRMSYMBOL = 'confirmSymbol',
  MAPLOADSTART = 'loadstart',
  MAPLOADEND = 'loadend',
  /** MAP start zooming or panning */
  MAPMOVESTART = 'movestart',
  /** Map zooming/panning to the end */
  MAPMOVENDED = 'moveend',
  /** Map rendered completed */
  MAPPOSTRENDER = 'postrender',
  /** Map render start */
  MAPPRERENDER = 'prerender',
  /** open property panel */
  FEATURESELECTED = 'featureSelected',
  /** close property panel */
  FEATUREDESELECT = 'featureDeselect',
  FEATUREDELETED = 'featureDeleted',
  FEATURESDELETED = 'featuresDeleted',
  /** ? */
  MODIFYFEATUREMODE = 'modifyFeatureMode',
  MOVEFEATUREMODE = 'moveFeatureMode',
  AUTOFEATUREMODE = 'autoFeatureMode',
  REVIEWFITTINGS = 'reviewFittings',
  REVIEWCANCELED = 'reviewCanceled',
  REVIEWAPPLIED = 'reviewApplied',
  REVIEWSYSTEMS = 'reviewSystems',
  FLYTOFEATURE = 'flyToFeature',
  /** fly to feature from total correction table */
  FLYTOFEATUREONLEFT = 'flyToFeatureOnLeft',
  RECHECKFEATURE = 'reCheckFeature',
  UNCHECKFEATURE = 'unCheckFeature',
  FORCEUPDATEMAPSIZE = 'forceUpdateMapSize',
  /** @deprecated in favor of `OPEN_TOTAL_TABLE - @2023/12/07` */
  POPUPDUCTCORRECTMODAL = 'popupDuctCorrect',
  POPUPUNSAVINGMODAL = 'popupUnsavingConfirm',
  PAGESELECTED = 'pageSelected',
  PAGEDESELECT = 'pageDeselected',
  PAGESELECTALL = 'pageSelectAllNew',
  PAGEDESELECTALL = 'pageDeselectAllNew',
  /** AI job request sent */
  JOBSTARTED = 'jobStarted',
  /** job status changed - 2024/02/23 */
  JOB_STATUS_CHANGE = 'jobStatusChanged',
  /** job progress notification - 2024/02/23 */
  JOB_PROGRESS_CHANGE = 'jobProgressChanged',
  /** job done with status `complete` - 2024/04/15 */
  JOB_STATUS_DONE = 'jobStatusComplete',
  /** File extraction, and AI job status polling */
  STARTPOLLING = 'startPolling',
  /** AI job  COMPLETED @deprecated */
  STOPPOLLING = 'stopPolling',
  /** AI job error */
  ERRORPOLLING = 'errorPolling',
  FILEXTRACTCOMPLETE = 'fileExtractComplete',
  FILEXTRACTFAILURE = 'fileExtractFailure',
  MAPLEVELCHANGED = 'mapLevelChanged',
  FILTERSYMBOLS = 'filterSymbols',
  /** cover symbols with white */
  REVERSYMBOLS = 'reverseSymbols',
  /** hide text toggling */
  REVERSESIZEDISPLAY = 'reverseSizeText',
  /**
   * Real saving event from `useTopNavBar`, then be processed in `useSymbolSaving`
   */
  SAVECHANGES = 'saveChanges',
  /**
   * Saving changed symbols done!
   */
  SAVECHANGESCOMPLETED = 'saveChangesCompleted',
  /**
   * Save correction from table view with `silent` mode or non-`silent` mode.
   * then relay to `SAVECHANGES` event
   */
  SAVETOTALCORRECTIONS = 'saveTotalCorrections',
  NEWSYMBOLSAVED = 'newSymbolSaved',
  /** delete one symbol from store in `reducer` by page & symbol */
  CLEARSYMBOL_FROM_STORE = 'clearSymbolFromStore',
  /** draw new symbol and cache it to reducer store */
  ADDSYMBOL_TO_STORE = 'addSymbolToStore',
  /** move/resize symbol, and correction properties */
  MODSYMBOL_IN_STORE = 'upadateSymbolInStore',
  /**
   * synchronize correction of multiple symbols in store,
   * if event detail is empty, then schedule a auto-saving
   * @date 2024/09/10
   */
  SYNCBATCH_IN_STORE = 'synchronizeBatchInStore',
  /** stroke all the symbols of selected system */
  HIGHLIGHTSYSTEM = 'highlightSystem',
  /** cancel highlighted symbols */
  DELIGHTSYSTEM = 'delightSystem',
  /** batch features selected by drag selection */
  BATCHSELECTION = 'batchFeatureSelected',
  /** cache dimension info update to features & symbols to save later */
  BATCHCORRECTION = 'batchFeatureCorrected',
  /** quietly refresh(re-fetch) page symbols AFTER changes saved */
  REFRESHSYMBOLS = 'refreshSymbols',
  /** reload symbols after page patched, need spinner support */
  RELAODSYMBOLS = 'reloadSymbols',
  /** get mouse position or pulling distance */
  MOUSE_REVEALED = 'mouseRevealed',
  /** retry AI job */
  RESTART_AI_JOB = 'retryAIJob',
  /** set magic brush setting to map */
  APPLY_BRUSH_SETTING = 'applyBrushSetting',
  /** show symbols same as table */
  SYNC_SYMBOLS_FROM_TABLE = 'syncSymbolsFromTable',
  /** mute symbol store changed, unset the global `change` state from `useSymbolsEditStore` */
  MUTE_SYMBOL_CHANGE = 'muteSymbolChangeState',
  /** toggle item type filter by select symbol id from table view */
  TOGGLE_ITEM_TYPE = 'toggleItemTypeFromSymbolId',
  /** notify drawing that total table opened, then zoom-in drawing to make it larger */
  TOTAL_TABLE_OPENED = 'totalTableOpened',
  /** notify `TotalReviewOpener` to open table correction panel */
  OPEN_TOTAL_TABLE = 'openTotalReviewTable',
  /** cancel drawing tool */
  CANCEL_DRAWING_TOOL = 'cancelDrawingTool',
  /** cancel saving schedule */
  CANCEL_SAVING_CHANGES = 'holdOffScheduledSaving',
  /** open feature properties panel */
  OPEN_FEATURE_PROPERTIES = 'openFeaturePropertiesPanel',
  /** open batch selected features panel */
  OPEN_BATCH_SELECTIONS = 'openBatchSelectionPanel',
  /** close feature panels */
  CLOSE_SIDE_PANEL = 'closeFeatureSelectionPanel',
  /** large file uploading progress */
  UPLOAD_PROGRESS = 'uploadProgress',
  /** TODO: ... */
}
