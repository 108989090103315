// import acvIcon from '@/assets/fittings/ACV_v1.svg';
// import backFlowPreventerIcon from '@/assets/fittings/Back_Flow_Preventer.svg';
// import ballValeIcon from '@/assets/fittings/Ball_Valve.svg';
// import butterFlyValveIcon from '@/assets/fittings/Butterfly_valve_v1.svg';
// import cappedPipeIcon from '@/assets/fittings/Capped_pipe_v1.svg';
// import cbvIcon from '@/assets/fittings/CBV.svg';
// import checkValveIcon from '@/assets/fittings/Check_Valve_v1.svg';
// import eccentricReducerIcon from '@/assets/fittings/eccentric_reducer.svg';
// import endpointIcon from '@/assets/fittings/Endpoint_B.svg';
// import exhaustIcon from '@/assets/fittings/exhuast_duct_v1.svg';
// import fireDamperIcon from '@/assets/fittings/Fire_Damper.svg';
// import gateValveIcon from '@/assets/fittings/Gate_Valve.svg';
// import manualBalanceDamperIcon from '@/assets/fittings/Manual_balancing_damper_v1.svg';
// import mbvIcon from '@/assets/fittings/MBV.svg';
// import meterIcon from '@/assets/fittings/Meter_v1.svg';
// import motorizedFireSmokeDamperIcon from '@/assets/fittings/Motorized_Combination_Fire_and_Smoke_Damper.svg';
// import perimeterDiffuserIcon from '@/assets/fittings/perimeter_new_v2.svg';
// import pipeDownEndpointA from '@/assets/fittings/Pipe_Down_Endpoint_A.svg';
// import pipeUpIcon from '@/assets/fittings/Pipe_up_v1.svg';
// import pressureGaugeIcon from '@/assets/fittings/Pressure_gauge_v1.svg';
// import prvIcon from '@/assets/fittings/PRV.svg';
// import pumpIcon from '@/assets/fittings/Pump.svg';
// import returnDuctDownIcon from '@/assets/fittings/Return_duct_down.svg';
// import returnDuctIcon from '@/assets/fittings/Return_Duct_fix.svg';
// import roundDuctDownIcon from '@/assets/fittings/Round_duct_down.svg';
// import roundDuctUpIcon from '@/assets/fittings/Round_duct_up.svg';
// import shutOffValveIcon from '@/assets/fittings/Shutoff_valve.svg';
// import solenoidValveIcon from '@/assets/fittings/Solenoid_Valve_v1.svg';
// import srvIcon from '@/assets/fittings/SRV.svg';
// import strainerIcon from '@/assets/fittings/Strainer_v1.svg';
// import supplyAirDiffuserIcon from '@/assets/fittings/Supply_Air_Diffuser.svg';
// import supplyOutsideDuctIcon from '@/assets/fittings/Supply_air_duct.svg';
// import thermometerIcon from '@/assets/fittings/thermometer.svg';
// import unionIcon from '@/assets/fittings/Union_v1.svg';
// import vcProvislonIcon from '@/assets/fittings/V_C_Provislon.svg';
// import yJunctionIcon from '@/assets/fittings/Y_junction.svg';

import centerLineReducerIcon from '@/assets/fittings/center_line_reducer.svg';
import elbowIcon from '@/assets/fittings/Elbow.svg';
import elbowPipingIcon from '@/assets/fittings/ELBOW_PIPING_icon.svg';
import tJunctionIcon from '@/assets/fittings/T_JUNCTION_icon.svg';
import helpIcon from '@/assets/icons/ic_outline-help.svg';
import widthIcon from '@/assets/icons/width_icon.svg';

export const missingIcons: { [key: string]: string } = {
  ['ELBOW_PIPING']: elbowPipingIcon,
  ['ELBOW_HVAC']: elbowIcon,
  ['SPIN_IN']: centerLineReducerIcon,
  ['T_JUNCTION']: tJunctionIcon,
};

export const HELP_ICON = helpIcon;
export const WIDTH_ICON = widthIcon;
