import { PROJECTS_GALLERY_SLIDES as PS } from '@/config';

import {
  JOBSTATUS,
  FITTING_TYPE,
  FILESTATUS,
  BPPage,
  TKProject,
  TKProjectPlus,
  TKBlueprint,
  unitSuffixs,
  PAGE_UNIT,
} from '../types';

import {
  TabHandleType,
  TabNavigateAction,
  SymbolsHandleType,
  SymbolsCRUDAction,
  RIBBONHANDLETYPE,
  RIBBONTOOLACTION,
  SymbolsFilteringAction,
  SYMBOLFILTERTYPE,
  HomeProjectsAction,
  HOMEPROJECTSACTYPE,
  AlgoInputAction,
  ALGOINPUTACTTYPE,
  ProjectXtractAction,
  PROJECTXTRACTIONTYPE,
} from './actions';
import * as selectors from './selectors';

import {
  PageOpenState,
  SymbolsGroupState,
  RibbonToolState,
  SymbolsReviewState,
  HomeProjectState,
  AlgoInputState,
  ProjectDetailState,
  getJobStatus,
} from '.';

// TODO: === GUIDE: for any reducer implementation: ===
// 1. state interface definition in `common.ts`;
// 2. payload interface definition in `common.ts`;
// 3. action & action type definition in `actions.ts`;
// 4. expose `initialXXXState` object in this file;
// 5. implement `xxxReducer` with `switch...case` statement in thi file;
// 6. implement `selectXXX` selector in `selectors.ts` for reducer cases if complex logic;
// 7. apply `useReducer` hook with init state and reducer in a hook;

// ================ PROJECT DETAIL, BLUEPRINTS, PAGES STATE MANAGEMENT ============

export const initialProjectDetail: ProjectDetailState = {
  projectId: '',
  loading: false,
  project: {},
  blueprints: [],
  fileCount: 0,
  unfinishedFiles: [],
  pages: [],
  totalPagesInProject: 0,
  hasNoJobAttached: undefined,
  allPagesLoaded: false,
};

/**
 * total project detail update
 * @param state
 * @param action
 * @returns
 */

export const projectDetailReducer = (
  state: ProjectDetailState,
  action: ProjectXtractAction,
): ProjectDetailState => {
  const { type, payload } = action;
  switch (type) {
    case PROJECTXTRACTIONTYPE.START_LOADING_PROJECT: {
      const projectId = payload.projectId as string;
      return { ...state, loading: true, projectId };
    }
    case PROJECTXTRACTIONTYPE.FINISH_LOADING_ANYWAY: {
      return { ...state, loading: false };
    }
    case PROJECTXTRACTIONTYPE.SET_PROJECT_DETAIL: {
      const project = payload.project as TKProject;
      const totalPagesInProject = project.project_total_pages || 0;
      return { ...state, project, totalPagesInProject };
    }
    case PROJECTXTRACTIONTYPE.SET_BLUEPRINTS: {
      const blueprints = payload.blueprints as TKBlueprint[];
      /**
       * Blueprints in the process of extraction,
       */
      const unfinishedFiles = blueprints
        .filter((b) => b.status !== FILESTATUS.COMPLETE)
        .filter((b) => b.status !== FILESTATUS.PRESIGNED); // do not show this type!

      return { ...state, blueprints, unfinishedFiles };
    }
    case PROJECTXTRACTIONTYPE.SET_FILE_COUNT: {
      const fileCount = payload.fileCount as number;
      return { ...state, fileCount };
    }
    case PROJECTXTRACTIONTYPE.SET_PAGES_FOR_BP: {
      const { pages, totalPagesInProject } = state;
      const hasNoJobAttached = pages.find((p) => {
        const { status } = getJobStatus(p.id);
        // check the no-job associated page
        return !p.job_id && !status;
      });
      const allPagesLoaded = totalPagesInProject <= pages.length;
      return { ...state, pages, hasNoJobAttached, allPagesLoaded };
    }
    case PROJECTXTRACTIONTYPE.SET_PROJECT_FIELD: {
      const { projectField, projectValue } = payload;
      const fieldStr = projectField as string;
      // new project:
      const project = { ...state.project, [fieldStr]: projectValue };
      return { ...state, project };
    }
    case PROJECTXTRACTIONTYPE.CLEAN_UP_PAGES: {
      return { ...state, blueprints: [], pages: [] };
    }
    // case PROJECTXTRACTIONTYPE.RELOAD_PAGES: {
    //   return state;
    // }
    // case PROJECTXTRACTIONTYPE.START_LOADING_BP: {
    //   return state;
    // }
    // case PROJECTXTRACTIONTYPE.END_LOADING_BP: {
    //   return state;
    // }
    default:
      return state;
  }
};

// ============= ALGO INPUT STATE MANAGEMENT =======================

export const initialAlgoInputState: AlgoInputState = {
  ceilingHeight: '',
  floorHeight: '',
  scale: '4',
  unit: '',
  unitSuffix: 'M',
};

export const algoInputsReducer = (
  state: AlgoInputState,
  action: AlgoInputAction,
): AlgoInputState => {
  const { type, payload } = action;
  switch (type) {
    case ALGOINPUTACTTYPE.SET_CEILING_HEIGHT: {
      return { ...state, ceilingHeight: payload.fieldValue || '' };
    }
    case ALGOINPUTACTTYPE.SET_FLOOR_HEIGHT: {
      return { ...state, floorHeight: payload.fieldValue || '' };
    }
    case ALGOINPUTACTTYPE.SET_SCALE_VALUE: {
      return { ...state, scale: payload.fieldValue || '' };
    }
    case ALGOINPUTACTTYPE.SET_UNIT_TYPE: {
      const unit = (payload.fieldValue || '') as PAGE_UNIT;
      const unitSuffix = unitSuffixs[unit];
      return { ...state, unit, unitSuffix };
    }
    case ALGOINPUTACTTYPE.SHOW_INPUTS_BY_PAGE: {
      const page = payload.page as BPPage;
      const unit = page.unit as PAGE_UNIT;
      const unitSuffix = unitSuffixs[unit];
      return {
        ...state,
        ceilingHeight: `${page.ceiling_height}`,
        floorHeight: `${page.floor_height}`,
        scale: `${page.scale}`,
        unit,
        unitSuffix,
      };
    }
    case ALGOINPUTACTTYPE.RESET_INPUTS_AFTER_JOB: {
      return {
        ...state,
        ceilingHeight: '',
        floorHeight: '',
        scale: '4',
      };
    }
    default:
      return state;
  }
};

export const initialHomeState: HomeProjectState = {
  /** one page of projects loading */
  loading: false,
  /** total projects with details, `ppList` originally */
  projectXOnePage: [],
  /** Projects for gallery only */
  latestProjects: [],
  /** current page */
  pageNum: 1,
  /** total projects number */
  totalProjectNum: 0,
  sortLastModified: false,
};

/**
 * Update home page state by actions
 *
 * @param state home page projects state
 * @param action home page pagination action
 * @returns new state
 */
export const homeProjectsReducer = (
  state: HomeProjectState,
  action: HomeProjectsAction,
): HomeProjectState => {
  const { type, payload } = action;
  switch (type) {
    case HOMEPROJECTSACTYPE.LOAD_PROJECTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case HOMEPROJECTSACTYPE.EXIT_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    case HOMEPROJECTSACTYPE.INIT_PROJECTS: {
      const projects = payload.projects as TKProject[];
      const totalProjectNum = payload.pageNum as number;
      // === STEP 1: initial project plus list with base info...
      const projectXOnePage = projects.map(selectors.projectPlusMaker);
      const latestProjects = projectXOnePage.slice(0, PS);
      return {
        ...state,
        projectXOnePage,
        latestProjects,
        totalProjectNum,
        sortLastModified: true, // reset to un-sorted
      };
    }
    case HOMEPROJECTSACTYPE.GOTO_PROJECTS: {
      const pageNum = payload.pageNum as number;
      return {
        ...state,
        pageNum,
        // latestProjects: [],
        // projectXOnePage: [],
      };
    }
    case HOMEPROJECTSACTYPE.REVS_PROJECTS: {
      const reversed = [...state.latestProjects].reverse();
      return {
        ...state,
        latestProjects: reversed,
        sortLastModified: !state.sortLastModified,
      };
    }
    case HOMEPROJECTSACTYPE.UPDT_PROJECTS: {
      const projectXOnePage = payload.projectx as TKProjectPlus[];
      const latestProjects = projectXOnePage.slice(0, PS);
      return {
        ...state,
        projectXOnePage,
        latestProjects,
      };
    }
    default:
      return state;
  }
};

// ======== Review Panel internal state managment ===========
export const initialSymbolsReviewState: SymbolsReviewState = {
  symbols: [],
  systems: [],
  allCompleted: true,
  userCompleted: false,
  allSymbolsDisplay: false,
  category: FITTING_TYPE.D as string,
  items: [],
  allItems: [],
  selectedItem: null,
  selectedSystem: 'All',
  groupSystems: [],
  fieldToSort: '',
  order: 0,
  filteredSymbols: [],
  totalCompletion: '',
};
/**
 * Check action to derive new state for next rendering loop
 * @param state
 * @param action
 */
export const symbolsReviewReducer = (
  state: SymbolsReviewState,
  action: SymbolsFilteringAction,
): SymbolsReviewState => {
  const { type, payload } = action;
  switch (type) {
    case SYMBOLFILTERTYPE.INIT_ITEMS: {
      return selectors.selectItemTypesFromSymbols(state, payload);
    }
    case SYMBOLFILTERTYPE.INIT_SYSTEMS: {
      return selectors.selectAllSystems(state, payload);
    }
    case SYMBOLFILTERTYPE.SET_CATEGORY: {
      return selectors.selectItemsFromCategory(state, payload);
    }
    case SYMBOLFILTERTYPE.SET_ITEM_TYPE: {
      return selectors.selectItemType(state, payload);
    }
    case SYMBOLFILTERTYPE.SET_SYSTEM_TYPE: {
      return selectors.selectSystemType(state, payload);
    }
    case SYMBOLFILTERTYPE.SET_CAT_AND_TYPE: {
      return selectors.selectCategoryAndItemType(state, payload);
    }
    case SYMBOLFILTERTYPE.SET_ALL_COMPLETED: {
      return selectors.selectAllCompletedSymbols(state, payload);
    }
    case SYMBOLFILTERTYPE.SET_USER_COMPLETED: {
      return selectors.selectUserConfirmedSymbols(state, payload);
    }
    case SYMBOLFILTERTYPE.SET_ORDERED_FIELD: {
      return selectors.selectColumnToSort(state, payload);
    }
    case SYMBOLFILTERTYPE.DEL_SYMBOL: {
      return selectors.selectSymbolToDelete(state, payload);
    }
    case SYMBOLFILTERTYPE.SHOW_ALL: {
      return selectors.selectAllSymbolShowInDrawing(state, payload);
    }
    case SYMBOLFILTERTYPE.CFM_SYMBOL: {
      return selectors.selectSymbolToConfirmed(state, payload);
    }
    case SYMBOLFILTERTYPE.FIND_CAT_AND_TYPE: {
      return selectors.findCategoryAndItemType(state, payload);
    }
    default:
      return state;
  }
};

// =============== ribbon settings panel state =======================
export const initialRibbonState: RibbonToolState = {
  fileId: '', // set by open file details page
  drawingId: '', // set by click thumbnail
  // initially all UPLOADED
  ribbonMode: JOBSTATUS.INIT, // set by click thumbnail later
};

export const ribbonToolsReducer = (
  state: RibbonToolState,
  action: RIBBONTOOLACTION,
): RibbonToolState => {
  const { type, payload } = action;
  switch (type) {
    case RIBBONHANDLETYPE.INIT: {
      return {
        ...state,
        fileId: payload.fileId,
      };
    }
    case RIBBONHANDLETYPE.RESET: {
      return {
        ...state,
        drawingId: payload.drawingId,
        ribbonMode: payload.ribbonMode,
      };
    }
    default:
      return state;
  }
};

// =============== symbols state for pages ===========================

export const initialSymbolsState: SymbolsGroupState = {
  fileId: '1',
  currentPage: '',
  /**
   * initilize a blank object to hold all the data pairs
   * key: page id
   * value: symbols array
   */
  pageDetections: {}, // {pageId: symbols[]}
  loading: false,
  reload: false,
};

export const symbolsReducer = (
  state: SymbolsGroupState,
  action: SymbolsCRUDAction,
): SymbolsGroupState => {
  const { type, payload } = action;
  switch (type) {
    // open loading symbols flag ....
    case SymbolsHandleType.LOAD:
      return selectors.selectLoadSymbols(state, payload);

    case SymbolsHandleType.EXIT: {
      return { ...state, loading: false, reload: false };
    }
    // symbols for page loaded, to render in the map...
    case SymbolsHandleType.INIT:
      return selectors.selectInitSymbolStore(state, payload);

    case SymbolsHandleType.DISPOSE:
      return selectors.selectDisposeSymbols(state, payload);

    case SymbolsHandleType.CLEAR:
      return selectors.selectClearSymbols(state, payload);

    // FIXME: change `mode` to DELETE rather than delete from cache,
    // in favor of store chagne detection
    // Also note: if deleted symbol is NEW, just delete it from cache
    // @2023/05/04
    case SymbolsHandleType.DELETE:
      return selectors.selectDeleteSymbols(state, payload);

    case SymbolsHandleType.ADD:
      return selectors.selectAddSymbols(state, payload);

    case SymbolsHandleType.UPDATE:
      return selectors.selectUpdateSymbols(state, payload);
    default:
      return state;
  }
};

// =============== root state for pages ===============================
export const initialTabState: PageOpenState = {
  tabs: [],
  currentTab: undefined,
  pages: [],
  currentPage: undefined,
};

export const tabsReducer = (
  state: PageOpenState,
  action: TabNavigateAction,
): PageOpenState => {
  const { type, payload } = action;

  switch (type) {
    case TabHandleType.UPDATE: {
      const changedPage = payload.page;
      if (!changedPage) return state;
      const pages = state.pages.map((p) =>
        p.id === changedPage?.id ? changedPage : p,
      );
      return {
        ...state,
        pages,
        currentPage: changedPage,
      };
    }
    // TODO: to mark green dot in the tab ...
    case TabHandleType.CHANGED: {
      const tabs = state.tabs.map((tab) => {
        if (tab.id === payload.id) tab.changed = payload.changed;
        return tab;
      });
      return {
        ...state,
        tabs,
      };
    }
    case TabHandleType.LOCK: {
      const currentPage = state.pages?.find((page) => page.id === payload.id);
      // Lock current page! this may not useful...
      // we intended to lock all the drawing edit!
      // @2024/09/25
      const pageWithLock = currentPage
        ? { ...currentPage, locked: payload.locked }
        : undefined;
      return {
        ...state,
        currentPage: pageWithLock,
      };
    }
    case TabHandleType.OPEN: {
      // This is multiple tabs!
      // const existed = state.tabs.find((tab) => tab.id === payload.id);
      // const tabs = existed ? [...state.tabs] : [...state.tabs, payload];
      // FIXME: this only display one tab!
      // @2023/05/08
      const tabs = [payload];
      tabs.forEach((tab) => (tab.selected = tab.id === payload.id));
      const currentPage = state.pages?.find((page) => page.id === payload.id);
      const currentTab = currentPage?.id;
      return {
        ...state,
        tabs,
        currentPage,
        currentTab,
      };
    }
    case TabHandleType.CLOSE: {
      return {
        ...state,
        tabs: [...state.tabs].filter((tab) => tab.id !== payload.id),
      };
    }
    case TabHandleType.SELECT: {
      const currentPage = state.pages?.find((page) => page.id === payload.id);
      return {
        ...state,
        currentTab: payload.id,
        currentPage,
      };
    }
    case TabHandleType.INIT: {
      return {
        ...state,
        pages: payload.pages || [],
      };
    }
    default:
      return state;
  }
};
