/**
 * Refactor all the modals creation from `FileDetailsView` to this dynamic component
 * @2023/02/04
 */

import { FC } from 'react';

import { ConfirmExtraProps, ModalComnProps } from '@/components/ui/modal';
import { MODAL_TYPES as MDT } from '@/types/options';

import { BPSymbol, EquipmentTag, TKSystem, SymbolEditParams } from '../types';

import { CustomFittingModal } from './add-custom-fitting';
import { AddDuctFitting } from './add-duct-fitting';
import { AddDuctLength } from './add-duct-length';
import { AddEquipmentModal } from './add-equipment';
import { AddPipeFitting } from './add-pipe-fitting';
import { AddPipeLengh } from './add-pipe-length';
import { BrushSettingModal } from './brush-setting';
import { DeleteConfirmModal } from './delete-confirm';
import { DiscardConfirmModal } from './discard-confirm';
import { EquipmentSettingModal } from './equipment-setting';
import { LeaveChangesConfirm } from './leave-changes-confirm';
import { LockFileModal } from './lock-file';
import { NewProjectSimpleModal } from './new-project-simple';
import { SummarySubTotal } from './summary-sub-total';

export type ModalItemProps = {
  /** DUCT|PIPE */
  category: string;
  /** from `modalContex` saved in `useModalAnimation` */
  selectType: string;
  itemId: string;
  systems: TKSystem[];
};

export type ModalExtraProps = {
  symbols: BPSymbol[];
  fileLocked: boolean;
  fileLockedHandler: () => void;
};

type TKModalProps = {
  commons: ModalComnProps;
  item: ModalItemProps;
  extra: ModalExtraProps;
  /** added at 2023/05/10 for summary report export */
  context: SymbolEditParams;
};

type TKConfirmProps = {
  commons: ModalComnProps;
  extra: ConfirmExtraProps;
};

type TKSettingExtraProps = {
  equipments: EquipmentTag[];
  onItemAdd: () => void;
  onItemUpdate: (index: number, equipment: EquipmentTag) => void;
  onItemSave: (eqmt: EquipmentTag) => Promise<void>;
  onItemDelete: (tag: string, index?: number) => void;
  onItemCleanup: () => void;
  onSaveAll: () => void;
};

type TKSettingProps = {
  commons: ModalComnProps;
  extra: TKSettingExtraProps;
};

// =========== Drawing symbol operations ============

/**
 * Create modal component by type
 */
const TKModals: { [key: string]: FC<TKModalProps> } = {
  [MDT.NEW_EQMT]: ({ commons }) => <AddEquipmentModal {...commons} />,
  [MDT.NEW_DUCT]: ({ commons, item, context }) => (
    <AddDuctFitting {...commons} {...item} {...context} />
  ),
  [MDT.NEW_PIPE]: ({ commons, item, context }) => (
    <AddPipeFitting {...commons} {...item} {...context} />
  ),
  [MDT.DUCT_LENGTH]: ({ commons, item, context }) => (
    <AddDuctLength {...commons} {...item} {...context} />
  ),
  [MDT.PIPE_LENGTH]: ({ commons, item, context }) => (
    <AddPipeLengh {...commons} {...item} {...context} />
  ),
  // === Lock drawing from edit ===
  [MDT.LOCK_FILE]: ({ commons, extra }) => (
    <LockFileModal {...commons} {...extra} />
  ),
  // === Summary modal ===
  [MDT.SUMMARY]: ({ commons, extra, context }) => (
    <SummarySubTotal {...commons} {...extra} context={context} />
  ),
  // === Brush setting modal ===
  [MDT.BRUSH_SETTING]: ({ commons, item }) => (
    <BrushSettingModal {...commons} {...item} />
  ),
  // == Custom Fitting modal ===
  [MDT.CUSTOM_FITTING]: ({ commons, item }) => (
    <CustomFittingModal {...commons} {...item} />
  ),
  // === defaut modal ===
  dummy: () => <div className="hidden">x</div>,
};

export const TKDynaModal = ({
  type,
  props,
}: {
  type: string;
  props: TKModalProps;
}) => {
  const DynaModal = TKModals[type] || TKModals.dummy;
  return <DynaModal {...props} />;
};

// ============= Confirm modals ============================
const dummyHandler = () => null;
const TKConfirms: { [key: string]: FC<TKConfirmProps> } = {
  // === add New Project modal to here,
  // this is a better place to stay rather than TKModals,
  // - @2023/08/22
  [MDT.NEW_PROJ]: ({ commons, extra }) => (
    <NewProjectSimpleModal
      {...commons}
      {...extra}
      projectCreatedHandler={extra.createHandler || dummyHandler}
    />
  ),
  [MDT.DEL_PAGE]: ({ commons, extra }) => (
    <DeleteConfirmModal {...commons} {...extra} />
  ),
  [MDT.DEL_PAGES]: ({ commons, extra }) => (
    <DiscardConfirmModal {...commons} {...extra} />
  ),
  [MDT.LEAVE_PAGE]: ({ commons, extra }) => (
    <LeaveChangesConfirm {...commons} {...extra} />
  ),
  dummy: () => <div className="hidden">x</div>,
};

/**
 * Confirmation modals & New Project dialog
 *
 * @param param0
 * @returns
 */
export const TKDynaConfirm = ({
  type,
  props,
}: {
  type: string;
  props: TKConfirmProps;
}) => {
  const DynaModal = TKConfirms[type] || TKConfirms.dummy;
  return <DynaModal {...props} />;
};

// ============== Setting modals ===========================

const TKSettings: { [key: string]: FC<TKSettingProps> } = {
  [MDT.EQUIPMENT]: ({ commons, extra }) => (
    <EquipmentSettingModal {...commons} {...extra} />
  ),
  // TODO: add ai job inputs modal ...
  //
  dummy: () => <div className="hidden">x</div>,
};

export const TKDynaSetting = ({
  type,
  props,
}: {
  type: string;
  props: TKSettingProps;
}) => {
  const DynaModal = TKSettings[type] || TKSettings.dummy;
  return <DynaModal {...props} />;
};
