import { QueryClient } from '@tanstack/react-query';
import { createBrowserRouter } from 'react-router-dom';

import {
  LOGIN_SUCCESS_ROUTE as home,
  TOKEN_UNAVAILABLE_FORWARD as sigin,
  SIGN_UP_COMPLETE_FORWARD as register,
  CONFIRM_EMAIL_FORWARD as confirm,
  TERMS_POLICY_FORWARD as terms,
  FORGOT_PASSWORD_FORWARD as forgot,
  RESET_PASSWORD_FORWARD as reset,
  CONFIRM_USER_INVITATION as accept,
} from '@/config';
import { ProtectedRoute } from '@/lib/protected-route';

import { PageSafeWrapper } from './safe-wrapper';

export const createRouter = (queryClient: QueryClient) =>
  createBrowserRouter([
    {
      path: '/',
      lazy: async () => {
        const { Landing } = await import('./landing');
        return { Component: Landing };
      },
    },
    {
      path: register,
      lazy: async () => {
        const { RegisterRoute } = await import('@/features/auth');
        return { Component: RegisterRoute };
      },
    },
    {
      path: sigin,
      lazy: async () => {
        const { LoginRoute } = await import('@/features/auth');
        return { Component: LoginRoute };
      },
    },
    {
      path: confirm,
      lazy: async () => {
        const { ConfirmEmail } = await import('@/features/auth');
        return { Component: ConfirmEmail };
      },
    },
    {
      path: terms,
      lazy: async () => {
        const { TermsAndPolicy } = await import('@/features/auth');
        return { Component: TermsAndPolicy };
      },
    },
    {
      path: forgot,
      lazy: async () => {
        const { ForgotPassword } = await import('@/features/auth');
        return { Component: ForgotPassword };
      },
    },
    {
      path: reset,
      lazy: async () => {
        const { PasswordReset } = await import('@/features/auth');
        return { Component: PasswordReset };
      },
    },
    {
      path: accept,
      lazy: async () => {
        const { ConfirmInvitation } = await import('@/features/auth');
        return { Component: ConfirmInvitation };
      },
    },
    // ********* HomePage Route *******************
    {
      path: home,
      element: (
        <ProtectedRoute>
          <PageSafeWrapper />
        </ProtectedRoute>
      ),
      children: [
        {
          path: 'admin-profile',
          lazy: async () => {
            const { AdminProfile } = await import(
              '@/features/users/routes/admin-profile'
            );
            return { Component: AdminProfile };
          },
        },
        {
          path: 'companies',
          lazy: async () => {
            const { CompanyList } = await import('@/features/users');
            return { Component: CompanyList };
          },
        },
        {
          path: 'users',
          lazy: async () => {
            const { UsersRoute } = await import('@/features/users');
            return { Component: UsersRoute };
          },
        },
        {
          path: 'user-profile',
          lazy: async () => {
            const { UserProfile } = await import('@/features/users');
            return { Component: UserProfile };
          },
        },
        // ********* Project Gallery *******************
        {
          index: true, // equals to: path: ''
          lazy: async () => {
            const { ProjectsHomeRoute } = await import('@/features/project');
            return { Component: ProjectsHomeRoute };
          },
        },
      ],
    },
    // ******** Project Routes ***************
    {
      path: '/project',
      element: (
        <ProtectedRoute>
          <PageSafeWrapper />
        </ProtectedRoute>
      ),
      children: [
        {
          path: ':projectId',
          lazy: async () => {
            const { ProjectDetails } = await import('@/features/project');
            return { Component: ProjectDetails };
          },
        },
        {
          path: ':projectId/file/:fileId/page/:pageId',
          lazy: async () => {
            const { FileDetailsView } = await import('@/features/project');
            return { Component: FileDetailsView };
          },
        },
      ],
    },
    {
      path: '*',
      lazy: async () => {
        const { NotFoundRoute } = await import('./not-found');
        return { Component: NotFoundRoute };
      },
    },
  ]);
