import clsx from 'clsx';
import { useState, useEffect } from 'react';

import widthIcon from '@/assets/icons/width_icon.svg';
import { AnimateModal, ModalOpenProps } from '@/components/ui/modal';
import { NEW_PIPE_LENGTH_TITLE, SELECT_MORE_SYSTEM } from '@/config';

import { useNormalView } from '../hooks/useNormalView';
import { RoundDiameterInput, CreateMoreSystems } from '../inputs';
import {
  TKEvent,
  FITTING_TYPE,
  CATALOGUE_TYPE,
  LENGTH_TYPE as LT,
  TKSystem,
  FittingEntity,
  getInputSuffixBy,
} from '../types';
import { getDimensionBy, setDimensionBy } from '../types/dimension-setting';

type PipeLenthProps = ModalOpenProps & {
  /**
   * LENGTH_TYPE.P
   */
  selectType: string;
  systems: TKSystem[];
  unit?: string;
};

/** Create Pipe Length Modal */
export const AddPipeLengh = ({
  isOpen,
  isOnStage,
  systems,
  unit,
  selectType,
  lazyCloseHandler,
}: PipeLenthProps) => {
  const [currentSystem, setCurrentSystem] = useState(''); // system belonged
  const [diameter, setDiameter] = useState('');
  const { isNormalView, setIsNormalView, normalView } = useNormalView();

  const applyNewElementHandler = () => {
    if (!diameter) return;

    const detail: FittingEntity = {
      category: CATALOGUE_TYPE.L,
      entityType: LT.P,
      system: currentSystem,
      diameter, // legacy dimension, just keep it
      diameter_1: diameter, // FIXME: use `diameter_1` instead @2023/08/24
      icon: widthIcon,
      toolType: LT.P, // selected tool for editor check - @2023/12/20
    };
    // lazy notification to map to add new element!
    setTimeout(() => {
      const event = new CustomEvent(TKEvent.NEWLINE, { detail });
      document.dispatchEvent(event);
    }, 200);

    lazyCloseHandler(currentSystem);

    // cache input fields for current duct type
    setDimensionBy(LT.P, { diameter, system: currentSystem });
  };

  // lazy close, and dispatch cancel tool event...
  const cancelNewElementHandler = () => {
    lazyCloseHandler();
    const evt = new Event(TKEvent.CANCEL_DRAWING_TOOL);
    document.dispatchEvent(evt);
  };

  /**
   * Reset system for new length addition by using cached dimension
   */
  useEffect(() => {
    const dimension = getDimensionBy(LT.P);
    if (!dimension) return;

    dimension['system'] && setCurrentSystem(dimension['system']);
    dimension['diameter'] && setDiameter(dimension['diameter']);
  }, []);

  return (
    <AnimateModal
      isOpen={isOpen}
      isOnStage={isOnStage}
      lazyCloseHandler={lazyCloseHandler}
      title={isNormalView ? NEW_PIPE_LENGTH_TITLE : SELECT_MORE_SYSTEM}
    >
      {/* == system field == */}
      <CreateMoreSystems
        systems={systems}
        category={FITTING_TYPE.P}
        itemType={selectType}
        currentSystem={currentSystem}
        currentSystemChangeHandler={setCurrentSystem}
        onMoreSystemSwitch={setIsNormalView}
      />
      {/* == name field == */}
      {/* <div className={normalView('pb-4')}>
        <DefaultInput
          fieldName="name"
          fieldValue={name}
          fieldLabel="NAME"
          fieldHandler={(_, value) => setName(value)}
          blurHandler={() => null}
        />
      </div> */}
      {/* diameter - length - height - width */}
      <div className={normalView('flex my-2')}>
        <RoundDiameterInput
          diameter={diameter}
          suffix={getInputSuffixBy(unit)}
          onChange={(event) => setDiameter(event.target.value)}
        />
      </div>
      {/* button row */}
      <div className="flex gap-x-10 justify-between mx-0 mt-6">
        {/* close modal */}
        <button
          className="btn-red-border color-red font-semibold flex-1 red-shadow"
          onClick={cancelNewElementHandler}
        >
          Cancel
        </button>
        {/* save button */}
        <button
          className={clsx(
            'btn-green flex-1 leading-5 text-base bg-green shadow-md',
          )}
          onClick={applyNewElementHandler}
        >
          Apply
        </button>
      </div>
    </AnimateModal>
  );
};
