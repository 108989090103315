/**
 * Created at 2023/08/18
 */
import { zoomifyBaseURL } from '@/config';

import { GO } from './common';
import { filterCompletionFields, getReducedFieldsByShape } from './dimension';
import { TKJobItem, TKItemCatalog, FittingMeta } from './entity';
import {
  findItemByType,
  findFieldsByType,
  LENGTH_CATALOGUES,
} from './fittings';
import { missingIcons, HELP_ICON, WIDTH_ICON } from './icons';
import { DETECTION_MODE, SYMBOL_REVISION } from './options';
import { BPSymbol } from './symbols';

/**
 * Find icon defined in item catalogue, if not found, use help icon instead
 *
 * @param itemType
 * @returns
 */
export const iconFinder = (itemType: string | undefined) => {
  if (!itemType) return HELP_ICON;
  const capitalize = itemType.toUpperCase();
  const length = LENGTH_CATALOGUES.find((it) => it.type === capitalize);
  if (length) {
    return WIDTH_ICON;
  }
  const commonItem = findItemByType(capitalize);
  if (!commonItem?.icon) {
    // console.warn(`>>> could not find icon for: ${capitalize}`);
    return missingIcons[capitalize] || HELP_ICON;
  }
  return commonItem.icon;
};

/**
 * Convert item catalouge definition to item meta data
 * @param item Item catalogue definition
 * @returns FittingMeta(Pipe) data
 */
export const itemCatalogueToFittingMeta = (
  item: TKItemCatalog,
): FittingMeta => {
  const fieldsNoSystem = item.properties
    .map((prop) => prop.name)
    .filter((field) => field !== 'system');
  const hasShape = fieldsNoSystem.includes('shape');
  const fieldsNoShape = fieldsNoSystem.filter((field) => field !== 'shape');
  const fittingMeta: FittingMeta = {
    rawType: item.type, // @2024/07/02
    category: item.category,
    type: item.id,
    label: item.description,
    icon: item.s3_icon_path
      ? `${zoomifyBaseURL}${item.s3_icon_path}`
      : missingIcons[item.id] || HELP_ICON,
    fields: fieldsNoShape,
    props: item.properties.map((prop) => prop.name),
    hasSysem: !!item.properties.find((prop) => prop.name === 'system'),
    properties: item.properties,
  };
  if (hasShape) {
    fittingMeta.shape = ['Round', 'Square'];
  }
  return fittingMeta;
};

/**
 * Compose job item state including `vers`, `completed`, `confirmed`
 * DO NOT LOOK `confirmed` as `completed`!
 * @date 2024/04/15
 *
 * @log vers equals to `D` is also considered completed - @2024/05/29
 *
 * @param item
 * @param detail
 * @returns
 */
export const checkJobItemState = (item: TKJobItem, detail: GO) => {
  const { detection_mode } = item;
  const confirmed = !!item.user_validated;
  const vers = checkJobItemVersionBy(
    item.item.id,
    detail,
    confirmed,
    detection_mode,
  );
  const isCompleted = vers === SYMBOL_REVISION.C;
  const isConfirmed = vers === SYMBOL_REVISION.D;
  const isManualSymbol = vers === SYMBOL_REVISION.M;
  const completed = isCompleted || isConfirmed || isManualSymbol;
  return { vers, completed, confirmed };
};

/**
 * General method to check symbol version:
 * Check symbol version if all dimension completed on first round features rendering
 *
 * USE CASE:
 * 1. jobItemTransformer at the jot items loading and transformation
 *
 * Add `shape` checking for version inference - @2023/11/03
 *
 * FIXME: check operation mode to decide version
 * @date 2024/03/21
 *
 * @param detail symbol detail
 * @returns version number
 */
const checkJobItemVersionBy = (
  itemType: string,
  detail: GO,
  userValidated?: boolean,
  mode?: string,
) => {
  if (mode === DETECTION_MODE.M) return SYMBOL_REVISION.M;
  // check manual confirmation first!
  if (userValidated) return SYMBOL_REVISION.D;

  const definedFields = findFieldsByType(itemType);
  const commonFields = ['system', ...definedFields];
  // consider `shape` for dimension
  const completionFields = filterCompletionFields(commonFields);
  const reducedFields = getReducedFieldsByShape(
    completionFields,
    detail['shape'],
  );

  const checkExistence = reducedFields.map(
    (f) => !!detail[f] && detail[f] !== '0',
  );
  const haveEmptyValue = checkExistence.includes(false);

  return haveEmptyValue ? SYMBOL_REVISION.B : SYMBOL_REVISION.C;
};

/**
 * Calculate completion percentage of a symbol in real time of symbol correction
 * FIXME: '0' input not a valid size to count for completion value
 * @2023/11/08
 *
 * @param symbol
 * @param fields dimension properties without 'system'
 * @returns
 */
export const completionCalculator = (
  symbol: BPSymbol,
  fields: string[],
): number => {
  if (fields.length == 0) return 0;
  const dimensions = symbol.detail;
  if (!dimensions) return 0;

  const { shape } = symbol.detail;
  if (!shape) {
    // console.warn(`### current symbol has no shape!`);
  }
  const completionFields = filterCompletionFields(fields);
  // console.log(`>>> calculate completion with shape: ${shape}`);
  const reducedFields = getReducedFieldsByShape(completionFields, shape);
  // console.log(reducedFields);
  const eachShare = Math.floor(100 / (reducedFields.length + 1));
  const completion = [...reducedFields, 'system'].reduce(
    (total: number, field: string) => {
      const validInput = !!dimensions[field] && dimensions[field] !== '0';
      return validInput ? total + eachShare : total;
    },
    0,
  );
  // FIXME: sometimes, the whole cells filled, but the completion is 99
  // so, +1 is required to make it `completed`!
  // @2023/11/10
  if (completion >= 99) return 100;

  return Math.min(completion, 100);
};
