/**
 * Define All the state here!
 * @date 2024/07/22
 */

import { ALL_DEFAULT_ITEM } from '@/config';

import {
  GO,
  AIModuleTypes,
  BPPage,
  BPSymbol,
  TKSystem,
  FittingMeta,
  SymbolFindResult,
  TKProjectPlus,
  TKProject,
  TKBlueprint,
} from '../types';

export const defaultItem: FittingMeta = {
  category: '',
  type: 'ALL',
  label: ALL_DEFAULT_ITEM,
  fields: [],
  props: [],
  icon: '',
  properties: [],
};

export interface ProjectDetailState {
  projectId: string;
  loading: boolean;
  project: GO;
  blueprints: TKBlueprint[];
  fileCount: number;
  unfinishedFiles: TKBlueprint[];
  pages: BPPage[];
  totalPagesInProject: number;
  hasNoJobAttached: BPPage | undefined;
  allPagesLoaded: boolean;
}

export interface ProjectDetailPayload {
  projectId?: string;
  project?: GO;
  projectField?: string;
  projectValue?: string | number;
  blueprints?: TKBlueprint[];
  fileCount?: number;
  pages?: BPPage[];
}

export interface AlgoInputState {
  floorHeight: string;
  ceilingHeight: string;
  scale: string;
  unit: string;
  unitSuffix: string;
}

export interface AlgoInputPayload {
  /** could be value of field `unit`|`floor`|`ceiling`|`scale` */
  fieldValue?: string;
  /** page object of current drawing to display in the right-bottom widget */
  page?: BPPage;
}

/**
 * job info
 */
export interface JobState extends AIModuleTypes {
  /** cacheed job id, this need to keep the up-to-date value for current page */
  jobId: string | null;
  status: string | null;
}

export interface SelectedPage {
  /** Page id to run AI job */
  id: string;
  /** blueprint file id */
  fileId: string;
  /** private Modules to apply */
  aiModules: string[];
}

/**
 * State passed from page preview card to file detials view
 */
export interface PageContext {
  /** Selected page id to open up */
  id: string;
  projectId: string;
  projectName: string; // added at 2024/11/06 for project export
  fileName: string;
  shortFileName?: string;
  relatives: BPPage[];
  /** current page */
  page: BPPage;
  /** job status cache @2023/06/06 */
  jobCache: { [pageId: string]: JobState };
}

/**
 * tab transient payload for state change
 * first created at `mDrawingTabOpener/usePageThumbnails`
 */
export interface TabState {
  /** this is page id must to have */
  id: string;
  label?: string; // some actions doesnt need this
  title?: string; // full length label to show file name @2023/12/12
  locked?: boolean; // if lock drawing from editing
  selected?: boolean; // some actions doesnt need this
  status?: string; // page state(PAGESTATUS) used to decide the ribbon settings statu
  pages?: BPPage[]; // all the pages in the sidebar
  /** if symbols in this page changed, TODO: when to update this? */
  changed?: boolean;
  /** change page object by update `scale` value in algo-widget/drawing page - 2024/07/26 */
  page?: BPPage;
}

/**
 * root state schema(for tab open/close/select)
 */
export interface PageOpenState {
  tabs: TabState[];
  /** Selected page(tab) id */
  currentTab: string | undefined;
  /** all the pages loaded in the sidebar */
  pages: BPPage[];
  /** Selected page object, could be undefined in unselected case */
  currentPage: BPPage | undefined;
}

/**
 * symbols state change payload
 */
export interface SymbolState {
  /** file id */
  id?: string;
  /** page id: current loading or loaded page, required! */
  page: string;
  /** symbol id or symbol object, used to CRUD one symbol in cache */
  symbol?: string | BPSymbol;
  /** loaded from remote and cache into the store */
  symbols?: BPSymbol[];
  /** refresh all sybmols after saving */
  reload?: boolean;
}

/**
 * Symbols state schema
 */
export interface SymbolsGroupState {
  fileId: string;
  /**
   * pageId: symbols
   */
  pageDetections: { [key: string]: BPSymbol[] };
  /**
   * loading status
   */
  loading: boolean;
  /**
   * current page id
   */
  currentPage: string;
  /**
   * if is refresh all the items,
   * this is used in refresh of all the symbol after auto-saving completed
   */
  reload?: boolean;
}

/**
 * ribbon setting display
 */
export interface RibbonToolState {
  fileId?: string; // file id
  /** current page id */
  drawingId?: string;
  drawingMode?: string;
  ribbonMode?: string; // tool panel state
}

export interface SymbolsReviewState {
  /** keep an original symbol records to use later */
  symbols: BPSymbol[];
  /** keep all the systems */
  systems: TKSystem[];
  /**
   * systems by category
   */
  groupSystems: TKSystem[];
  /** show all confirmed symbols or not, false by default */
  allCompleted: boolean;
  /** show user confimed symbols, false by default */
  userCompleted: boolean;
  allSymbolsDisplay: boolean;
  /**
   * group: DUCT | PIPE | DUCT_LENGTH | PIPE_LENGTH | EQUIPMENT
   */
  category: string;
  /**
   * current of the item-types from selected category
   */
  items: FittingMeta[];
  /**
   * all the item types form current drawing symbols
   */
  allItems: FittingMeta[];
  /**
   * selected sub-type, null by default
   */
  selectedItem: FittingMeta | null;
  selectedSystem: string;
  fieldToSort: string;
  order: number;
  filteredSymbols: SymbolFindResult[];
  totalCompletion: string;
}

export interface SymbolsFilteringPayload {
  symbols?: BPSymbol[];
  systems?: TKSystem[];
  category?: string;
  itemType?: string;
  /** possibly category or item-type */
  topFilter?: string;
  systemType?: string;
  showAllCompleted?: boolean;
  showUserConfirmed?: boolean;
  fieldToSort?: string;
  order?: number;
  symbolId?: string;
  displayAll?: boolean;
}

export interface HomeProjectState {
  loading: boolean;
  /** total projects with details from `useProjectInfoQue` hook */
  projectXOnePage: TKProjectPlus[];
  /** Projects for gallery only */
  latestProjects: TKProjectPlus[];
  /** current page */
  pageNum: number;
  /** total projects number */
  totalProjectNum: number;
  /** sort order */
  sortLastModified: boolean;
}

export interface HomeProjectsPayload {
  projects?: TKProject[];
  projectx?: TKProjectPlus[];
  pageNum?: number;
}
