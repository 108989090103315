import {
  TK_API_PROJECTS,
  TK_API_ITEMS,
  TK_API_PROPERTIES,
  STATES_OF_NORTHAMERICA,
  CONSTRUCTION_SECTORS,
  PROJECT_TYPES,
  PROJECT_STAGES,
} from '@/config';
import { resetBaseURL, uploadURL, downloadFile } from '@/lib/axios-v1';
import { formatDateByMDHM } from '@/utils';
import { error } from '@/utils/logger';

import {
  APIResponse,
  GenericObject as GO,
  PAGESTATUS,
  PROJECTSTATE,
  NewJobItem,
  SegIndex,
  SymbolSegment,
  AIJobScaleParams,
  EquipmentTag,
  PageEditParams,
  SymbolEditParams,
  StateOrProvince,
  ProjectMetaOption,
} from '../types';

import { buildProjectPayload, mockCompanyID } from './mock';

export * from './normalize';
export * from './mock';
export * from './upload';

// ========= A simple service locker for avoid repetitive request =======

type Request = (...args: any[]) => Promise<void>;

/**
 * Safer way of calling API to avoid repetitive calling!
 *
 * @date 2024/03/16
 */
export const singletonRequest = {
  loading: false,
  call: async function (api: Request, ...args: any[]) {
    if (this.loading) return;

    this.loading = true;
    await api.apply(this, args);
    this.loading = false;
  },
};

// >>>>>>>>>>>>>> Project sidebar meta data APIs <<<<<<<<<<<<<<

/**
 * Get list of states for a specific country
 * @returns
 */
export const fetchStatesOfCountry = (): Promise<StateOrProvince[]> => {
  return resetBaseURL().get(STATES_OF_NORTHAMERICA);
};

/**
 * Get list of Construction Sectors:
 * @returns
 */
export const fetchConstructionSectors = (): Promise<ProjectMetaOption[]> => {
  return resetBaseURL().get(CONSTRUCTION_SECTORS);
};

/**
 * Get list of Project Types
 * @returns
 */
export const fetchProjectTypes = (): Promise<ProjectMetaOption[]> => {
  return resetBaseURL().get(PROJECT_TYPES);
};

/**
 * Get list of Project Stages
 * @returns
 */
export const fetchProjectStages = (): Promise<ProjectMetaOption[]> => {
  return resetBaseURL().get(PROJECT_STAGES);
};

/**
 * Confirm one symbol
 * @param editCtx
 * @param item_id
 * @param index
 * @returns
 */
export const confirmOneSymbol = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}`;
  const payload = {
    user_validated: 1,
  };
  return resetBaseURL().patch(url, payload);
};

/**
 * Confirm multiple symbols
 * @param editCtx
 * @param items
 * @returns
 */
export const confirmMultipleSymbols = (
  editCtx: SymbolEditParams,
  items: { item_id: string; index: number }[],
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items`;
  const payload = items.map((it) => ({
    ...it,
    user_validated: 1,
  }));
  return resetBaseURL().patch(url, payload);
};

// ================= TODO: PROJECT HANDLING =============================

/**
 * Hide multiple pages
 * If we just want to hide the pages, you may just need to change pages' status to "INACTIVE":
 * URL: {{url}}projects/{{id_project}}/blueprints/{{id_blueprint}}/pages
 * Method: PATCH
 * Payload example:
 *[
 *   {
 *       "page_id":"c30f32a0-36f9-4eb9-8a95-7dc2c3c363ea",
 *        "status":"INACTIVE"
 *   },
 *      {
 *       "page_id":"166eb16a-ca7b-478c-b8b4-3df9a71b9fa5",
 *        "status":"INACTIVE"
 *   }
 *]
 * if you just hide pages (change their status) you need to filter out such status on the frontend.
 *
 * @param pId project id
 * @param bId blueprint id
 * @param pageIds page id list
 * @returns promise
 */
export const archiveMultiPages = (
  pId: string,
  bId: string,
  pageIds: string[],
) => {
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages`;
  const pages = pageIds.map((id) => ({ page_id: id, status: 'INACTIVE' }));
  return resetBaseURL().patch(url, pages);
};

/**
 * NOTE: USE THIS WITH CAREFULNESS!
 *
 * An API for hard-deleting pages is available:
 *
 * URL: {{url}}projects/{{id_project}}/blueprints/{{id_blueprint}}/pages
 *
 * Method: DELETE
 *
 * Payload example:
 * [
 *     {
 *         "page_id":"5752e237-a2c0-449b-b2d7-317b34728afb"
 *     },
 *       {
 *         "page_id":"80e7f9f7-5aca-434d-97ed-27f3c99104e5"
 *     }
 * ]
 * This methods expects an array of pages to be physically deleted.
 *
 * @param pId project id
 * @param bId blueprint(pdf file) id
 * @param pageIds page id list from the same blueprint
 * @returns promise
 */
export const deleteMultiPages = (
  pId: string,
  bId: string,
  pageIds: string[],
) => {
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages`;
  const pages = pageIds.map((id) => ({ page_id: id }));
  return resetBaseURL().delete(url, { data: pages });
};

/**
 * Hard & permanant deletion not reversible
 *
 * Completly delete a project and any related data (e.g., blueprints, pages, results, etc):
 * URL: {url}v1/projects/{project_id}
 * Method: DELETE
 * Please note that this operation cannot be reversed.
 * @param projectId project id
 * @returns promise
 */
export const deleteProject = (projectId: string) => {
  const url = `${TK_API_PROJECTS}/${projectId}`;
  return resetBaseURL().delete(url);
};

/**
 * Hide the project from display
 * Changing the project' status:
 * URL: {url}v1/projects/{id_project}
 * Method: PATCH
 * Payload:
 * {
 *     "status":"INACTIVE"
 * }
 * If we use (2), we should consider the following:
 * a) We need to agree on a status that reflects ACTIVE and INACTIVE projects.
 * b) In the frontend, you may use such statuses to filter the projects as follows:
 *
 * @param projectId project id
 * @returns promise
 */
export const archiveProject = (projectId: string) => {
  const url = `${TK_API_PROJECTS}/${projectId}`;
  const payload = { status: PROJECTSTATE.I };
  return resetBaseURL().patch(url, payload);
};

// ================= TODO: PIPE STRUC CHAGNES, Equipment API & AI report API =============
// @2023/05/08, 10

/**
 * Fetch file Blob of current drawing or project
 * @date add project export with multi-sheet
 * @param editCtx report source
 * @param format file format: CSV | EXCEL | PROJECT
 * @returns file Blob
 */
export const exportDrawingReport = async (
  editCtx: SymbolEditParams,
  format = 'EXCEL',
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const singleExportURL = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/summary?format=${format}`;
  const isProjectExport = format === 'PROJECT';
  const projectExportURL = `${TK_API_PROJECTS}/${pId}/summary`;
  const finalURL = isProjectExport ? projectExportURL : singleExportURL;
  const file = (await downloadFile(finalURL)) as any;
  return file as Blob;
};

export const fetchDrawingSummary = async (editCtx: SymbolEditParams) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/summary?format=JSON`;
  const result = (await resetBaseURL().get(url)) as any[];
  return result;
};

export const getEquipmentTags = (projectId: string): Promise<APIResponse> => {
  const url = `${TK_API_PROJECTS}/${projectId}/equipment-tags`;
  return resetBaseURL().get(url);
};

export const addEquipmentTag = (
  projectId: string,
  eqTag: EquipmentTag,
): Promise<APIResponse> => {
  const url = `${TK_API_PROJECTS}/${projectId}/equipment-tags`;
  const payload = [eqTag];
  return resetBaseURL().post(url, payload);
};

export const modifyEquipmentTag = (
  projectId: string,
  eqTag: EquipmentTag,
): Promise<APIResponse> => {
  const { tag, project_id, ...payload } = eqTag;
  const url = `${TK_API_PROJECTS}/${projectId}/equipment-tags/${tag}`;
  return resetBaseURL().patch(url, payload);
};

export const deleteEquipmentTag = (projectId: string, tag: string) => {
  const url = `${TK_API_PROJECTS}/${projectId}/equipment-tags/${tag}`;
  return resetBaseURL().delete(url);
};

export const archivePageBy = (editCtx: PageEditParams) => {
  const { projectId: pId, fileId: bId, pageId: dId } = editCtx;
  const payload = { status: PAGESTATUS.INACTIVE };
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}`;
  return resetBaseURL().patch(url, payload);
};

// ================= START OF SYMBOL EDITING API ========================

export const deleteItemCoordinate = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  segment: number,
): Promise<APIResponse> => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/coordinates/${segment}`;
  return resetBaseURL().delete(url);
};

export const deleteItemCoordinates = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  coordinates: SegIndex[],
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/coordinates`;
  return resetBaseURL().delete(url, { data: coordinates });
};

export const updateItemCoordinate = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  coordinate: SymbolSegment,
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const { segment, ...payload } = coordinate;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/coordinates/${segment}`;
  return resetBaseURL().patch(url, payload);
};

export const updateItemCoordinates = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  coordinates: SymbolSegment[],
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/coordinates`;
  return resetBaseURL().patch(url, coordinates);
};

/**
 * Not in use
 */
export const addNewCoordinate = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  coordinate: SymbolSegment,
): Promise<APIResponse> => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/coordinates`;
  return resetBaseURL().post(url, coordinate);
};

/**
 * Create multiple manual job items
 * @param editCtx manual item context parameters
 * @param items job items
 * @returns
 */
export const addNewJobItems = (
  editCtx: SymbolEditParams,
  items: NewJobItem[],
): Promise<APIResponse> => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items`;
  return resetBaseURL().post(url, items);
};

export const deleteJobItem = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}`;
  return resetBaseURL().delete(url);
};

/**
 * Delete multiple manual job items
 * @param editCtx manual item context parameters
 * @param items job items
 * @returns
 */
export const deleteJobItems = (
  editCtx: SymbolEditParams,
  items: { item_id: string; index: number }[],
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items`;
  return resetBaseURL().delete(url, { data: items });
};

export const addPropertyForItem = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  field: string,
  value: string,
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/properties`;
  const payload = {
    name: field,
    value,
  };
  return resetBaseURL().post(url, payload);
};

/**
 * NOT IN USE
 *
 * @deprecated
 * @param editCtx
 * @param item_id
 * @param index
 * @param field
 * @param value
 * @returns
 */
export const updatePropertyForItem = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  field: string,
  value: string | number,
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/properties/${field}`;
  const payload = {
    value,
  };
  return resetBaseURL().patch(url, payload);
};

/**
 * Update multiple properties of one item
 * @param editCtx manual item context parameters
 * @param item_id item ID, e.g ELBOW
 * @param index item index
 * @param props properties to be updated
 * @returns
 */
export const updatePropertiesForItem = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  props: { name: string; value: string | number }[],
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/properties`;
  return resetBaseURL().patch(url, props);
};

export const deletePropertyForItem = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  field: string,
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/properties/${field}`;
  return resetBaseURL().delete(url);
};

/**
 * Delete multiple item properties
 * @param editCtx manual item context parameters
 * @param item_id item ID, e.g ELBOW
 * @param index item index
 * @param props properties to be updated
 * @returns
 */
export const deletePropertiesForItem = (
  editCtx: SymbolEditParams,
  item_id: string,
  index: number,
  props: { name: string; value: string | number }[],
) => {
  const { projectId: pId, fileId: bId, pageId: dId, jobId: jId } = editCtx;
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items/${item_id}/indexes/${index}/properties`;
  return resetBaseURL().delete(url, { data: props });
};

// ============  END OF SYMBOL EDITING API ========================

export const fetchItemCatalogue = (): Promise<APIResponse> => {
  return resetBaseURL().get(TK_API_ITEMS);
};

export const fetchSystems = (): Promise<APIResponse> => {
  return resetBaseURL().get(`${TK_API_PROPERTIES}/system/constants`);
};

/**
 * request to generate tiles for page
 *
 * @param pId project id
 * @param bId blueprint id
 * @param dId drawing/page id
 * @returns
 */
export const generateZoomifyTiles = (pId: string, bId: string, dId: string) => {
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/zoomify`;
  return resetBaseURL().post(url);
};

/**
 * Get job result items
 *
 * @param pId project id
 * @param bId blueprint id
 * @param dId drawing/page id
 * @param jId job id
 * @returns ai job result
 */
export const fetchJobResultItems = (
  pId: string,
  bId: string,
  dId: string,
  jId: string,
): Promise<APIResponse> => {
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}/items?init-collections=true`;
  return resetBaseURL().get(url, {
    // headers: { 'Accept-Encoding': 'br;q=1.0, gzip;q=0.8, *;q=0.1' },
  });
};

/**
 * Check job status:
 * > PENDING -> PROCESSING -> COMPLETE | ERROR
 *
 * @param pId project id
 * @param bId blueprint id
 * @param dId drawing/page id
 * @param jId job id
 * @returns
 */
export const fetchJobStatus = (
  pId: string,
  bId: string,
  dId: string,
  jId: string,
): Promise<APIResponse> => {
  const url = `${TK_API_PROJECTS}/${pId}/blueprints/${bId}/pages/${dId}/jobs/${jId}`;
  return resetBaseURL().get(url);
};

/**
 * Start an AI job with retry mechanism, it may be failed if drawing is too big for pipe AI module!
 *
 * So, use try...catch for this function call!
 *
 * @param projectId
 * @param blueprintId
 * @param pageId
 * @param payload
 * @param retry
 * @returns
 */
export const startAIjobWithParams = (
  projectId: string,
  blueprintId: string,
  pageId: string,
  payload: AIJobScaleParams,
  retry = false,
): Promise<APIResponse> => {
  const url = `${TK_API_PROJECTS}/${projectId}/blueprints/${blueprintId}/pages/${pageId}/jobs`;
  const withRetry = `${url}${retry ? '?retry=true' : ''}`; // @2023/05/24
  return resetBaseURL().post(withRetry, payload);
};

export const updatePageInfoWith = (
  projectId: string,
  blueprintId: string,
  pageId: string,
  payload: GO,
): Promise<APIResponse> => {
  const PAGES_PATH = `${TK_API_PROJECTS}/${projectId}/blueprints/${blueprintId}/pages`;
  const patchURL = `${PAGES_PATH}/${pageId}`;
  return resetBaseURL().patch(patchURL, payload);
};

/**
 * Get page details used in shared version of drawing details view
 *
 * @date 2023/06/08
 * @param projectId
 * @param blueprintId
 * @param pageId
 * @returns
 */
export const fetchPageById = (
  projectId: string,
  blueprintId: string,
  pageId: string,
): Promise<APIResponse> => {
  const PAGE_PATH = `${TK_API_PROJECTS}/${projectId}/blueprints/${blueprintId}/pages/${pageId}`;
  return resetBaseURL().get(PAGE_PATH);
};

export const fetchPagesByBlueprint = (
  projectId: string,
  blueprintId: string,
  mock?: boolean,
): Promise<APIResponse> => {
  const PAGES_PATH = `${TK_API_PROJECTS}/${projectId}/blueprints/${blueprintId}/pages`;
  return resetBaseURL().get(PAGES_PATH);
};

export const fetchBlueprintDetailBy = (
  projectId: string,
  blueprintId: string,
): Promise<APIResponse> => {
  const url = `${TK_API_PROJECTS}/${projectId}/blueprints/${blueprintId}`;
  return resetBaseURL().get(url);
};

export const fetchBlueprintsBy = (projectId: string): Promise<APIResponse> => {
  const BLUEPRINTS_PATH = `${TK_API_PROJECTS}/${projectId}/blueprints`;
  return resetBaseURL().get(BLUEPRINTS_PATH);
};

/**
 * Catch uploading error, especially Network error!
 * @deprecated in favor of 3-stage upload workflow on 2024/10/03
 * @data 2024/06/25
 * @param projectId
 * @param formData
 * @param userName
 * @returns
 */
export const uploadBluePrint = (
  projectId: string,
  formData: FormData,
  userName: string,
): Promise<APIResponse> => {
  const UPLOAD_URL_PATH = `${TK_API_PROJECTS}/${projectId}/blueprints`;
  return uploadURL()
    .post(UPLOAD_URL_PATH, formData)
    .then(
      // success callback and return response!
      function (resp: any) {
        return Promise.resolve(resp);
      },
      // exception callback and return response!
      function ({ message }: Error) {
        error(`>>> got pdf upload error: ${message}`);
        return Promise.resolve({ data: null, message });
      },
    );
};

export const updateProjectWith = (id: string, payload: GO) => {
  return resetBaseURL().patch(TK_API_PROJECTS + '/' + id, payload);
};

export const fetchProjectDetails = (id: string): Promise<APIResponse> => {
  return resetBaseURL().get(TK_API_PROJECTS + '/' + id);
};

/**
 * Fetch active project list for homepage.
 * TODO: with pagination params:
 * {{url}}projects?filters=id_account:eq:002,city:eq:TORONTO&page=1&limit=20
 *
 * Method: GET
 * URL  {url}v1/projects?filters=
 * where filters follows this structure:
 * field:operator:value
 * Available operators: [eq, neq, lte, gt, gte, contains, startswith, endswith]
 * For example:
 * ?filters=status:eq:ACTIVE
 * We can have more than one filter separated by a ",":
 * ?filters=status:neq:INACTIVE,id_account:eq:001
 *
 *
 * ADD `id_account` filter at 2023/09/19
 *
 * @param companyId
 * @returns
 */
export const fetchProjectList = (
  companyId = mockCompanyID,
  page?: number,
  limit?: number,
  city?: string,
): Promise<APIResponse> => {
  const params = `${companyId},status:neq:INACTIVE&page=${page}&limit=${limit}`;
  const queryURL = `${TK_API_PROJECTS}?filters=id_account:eq:${params}`;
  return resetBaseURL().get(queryURL);
};

/**
 * Create project with name and company
 *
 * @param name New project name
 * @param actid company id
 *
 * @returns
 */
export const saveNewProject = (
  name: string,
  actid = '001',
  userName = 'UNNO',
): Promise<APIResponse> => {
  const pid = formatDateByMDHM();
  const project = buildProjectPayload(actid, name, pid, userName);
  return resetBaseURL().post(TK_API_PROJECTS, project);
};

export const mockRequest = (...args: any): Promise<APIResponse> => {
  const mockResp = {
    code: 200,
    data: { documents: [] },
    message: 'success',
  };
  return new Promise((resolve, _) => {
    setTimeout(() => resolve(mockResp), 2000);
  });
};
